<template>
  <div>
    <h1>Apply Pass</h1>
    <div v-if="!selectedPass">
      <input
        class="input has-icons-left"
        type="text"
        v-model="passSearch"
        v-on:keyup="searchPasses"
        placeholder="Search by name or email"
      />
    </div>
    <div class="results" v-if="!selectedPass">
      <div
        v-for="pass in foundPasses"
        :key="pass.id"
        class="columns selectable"
        @click="setPass(pass)"
      >
        <div class="column">
          <p class="artistname">
            <strong
              >{{ pass.firstName }} {{ pass.lastName }} -
              {{ pass.email }}</strong
            >
          </p>
          <p class="passname">{{ pass.passname }}</p>
        </div>
        <div class="column is-narrow">
          <p class="artistname count">
            {{ getPassCounts(pass) }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="selectedPass">
      <div class="columns">
        <div class="column">
          <p class="artistname">
            <strong
              >{{ selectedPass.firstName }} {{ selectedPass.lastName }} -
              {{ selectedPass.email }}</strong
            >
          </p>
          <p class="passname">{{ selectedPass.passname }}</p>
        </div>
        <div class="column is-narrow">
          <p class="artistname count">
            {{ getPassCounts(selectedPass) }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="selectedPass" class="quantityPicker">
      <input
        type="radio"
        id="quantityone"
        class="is-checkradio"
        name="quantity"
        v-model.number="quantity"
        value="1"
      />
      <label for="quantityone">1</label>
      <input
        type="radio"
        id="quantitytwo"
        :disabled="maxQuantity < 2"
        class="is-checkradio"
        name="quantity"
        v-model.number="quantity"
        value="2"
      />
      <label for="quantitytwo">2</label>
      <input
        type="radio"
        id="quantitythree"
        :disabled="maxQuantity < 3"
        class="is-checkradio"
        name="quantity"
        v-model.number="quantity"
        value="3"
      />
      <label for="quantitythree">3</label>
      <input
        type="radio"
        id="quantityfour"
        :disabled="maxQuantity < 4"
        class="is-checkradio"
        v-model.number="quantity"
        name="quantity"
        value="4"
      />
      <label for="quantityfour">4</label>
      <input
        type="radio"
        id="quantityfive"
        :disabled="maxQuantity < 5"
        class="is-checkradio"
        v-model.number="quantity"
        name="quantity"
        value="255"
      />
      <label for="quantityfive">5</label>
    </div>
    <div v-if="selectedPass" class="columns">
      <div class="column">
        <input
          class="is-checkradio"
          id="alternativeObscure"
          type="checkbox"
          name="alternativeObscure"
          v-model="checkin"
          value="obscured"
        />
        <label for="alternativeObscure"
          >Check In</label
        >
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <button
          :disabled="!selectedPass"
          class="button is-fullwidth is-primary"
          @click="clear()"
        >
          Clear
        </button>
      </div>
      <div class="column">
        <button
          :disabled="!selectedPass"
          class="button is-fullwidth is-primary"
          @click="add('Credit')"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import firebase from "firebase/app";

export default {
  name: "ApplyPass",
  data() {
    return {
      quantity: 1,
      maxQuantity: 1,
      passSearch: "",
      foundPasses: [],
      selectedPass: null,
      checkin: false
    };
  },
  computed: {
    ...mapGetters({
      priceTiers: "priceTiers",
      dateSummary: "dateSummary",
      passes: "passes",
      event: "event"
    })
  },
  async created() {
    console.log("Get price tiers.");
    await store.dispatch("fetchPriceTiers");
  },
  methods: {
    getPassCounts(pass) {
      if (pass.passType == "unlimited") return "unlimited";
      const used = pass.usedCount ? pass.usedCount : "0";
      return used + " / " + pass.limit;
    },
    clear() {
      this.quantity = 1;
      this.passSearch = "";
      this.selectedPass = null;
    },
    searchPasses() {
      console.log("findPasses", this.passSearch);
      if (!this.passSearch) this.foundPasses = [];
      else {
        var foundpasses = this.passes.filter(pass => {
          if (
            pass.passType == "limited" &&
            pass.usedCount &&
            pass.limit &&
            pass.usedCount >= pass.limit
          )
            return false;
          if (
            pass.email &&
            (pass.email + "")
              .toUpperCase()
              .indexOf(this.passSearch.toUpperCase()) > -1
          )
            return true;
          if (
            pass.firstName &&
            (pass.firstName + "")
              .toUpperCase()
              .indexOf(this.passSearch.toUpperCase()) > -1
          )
            return true;
          if (
            pass.lastName &&
            (pass.lastName + "")
              .toUpperCase()
              .indexOf(this.passSearch.toUpperCase()) > -1
          )
            return true;
          return false;
          //   string email = pass.email ? pass.email.toUpperCase : "";
          //   const first = pass.firstName ? pass.firstName.toUpperCase : "";
          //   const last = pass.lastName ? pass.lastName.toUpperCase : "";
          //   return (
          //     email.indexOf(this.passSearch.toUpperCase()) > -1 ||
          //     first.indexOf(this.passSearch.toUpperCase()) > -1 ||
          //     last.toUpperCase().indexOf(this.passSearch.toUpperCase()) > -1
          //   );
        });
        foundpasses.length = foundpasses.length > 5 ? 5 : foundpasses.length;
        this.foundPasses = foundpasses;
      }
    },
    setPass(pass) {
      this.selectedPass = pass;
      this.quantity = 1;
      if (pass.passType == "unlimited") this.maxQuantity = 1;
      else
        this.maxQuantity = pass.usedCount
          ? pass.limit - pass.usedCount
          : pass.limit;
    },
    async add() {
      var db = firebase.firestore();
      let price = this.selectedPass.passType == "unlimited" ? 0 : 14;
      const lineItem = {
        checkedin: this.checkin ? this.quantity : 0,
        firstName: this.selectedPass.firstName,
        lastName: this.selectedPass.lastName,
        email: this.selectedPass.email,
        passId: this.selectedPass.passId,
        passInstanceId: this.selectedPass.id,
        tierName: this.selectedPass.passname,
        quantity: this.quantity,
        price: price,
        total: price * this.quantity,
        status: this.checkin ? "checkedin" : "paid"
      };
      db.collection("events")
        .doc(this.event.id)
        .collection("userData")
        .doc("audienceReport")
        .collection("dates")
        .doc(this.dateSummary.dateId)
        .collection("audience")
        .add(lineItem)
        .catch(function(error) {
          console.error("Error adding walkup: ", error);
        });
      this.quantity = 1;
      this.passSearch = "";
      this.selectedPass = null;
      this.$emit("passApplied");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.results {
  margin-top: 30px;
}
.selectable:hover {
  background-color: $gray6;
  cursor: pointer;
}
.passname {
  font-size: 14px;
  line-height: 4px;
}
.count {
  margin-right: 10px;
}
.quantityPicker {
  margin: 20px 0 30px;
}
</style>
