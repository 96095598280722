<template>
  <div class="box">
    <h3>Venue Profile</h3>
    <p class="overview">
      You're almost there! Tell us a little about your venue and we'll get you
      started booking artists and selling tickets.
    </p>
    <div class="field">
      <label class="label has-text-left">Venue Name</label>
      <div class="control">
        <input
          class="input is-primary"
          type="text"
          placeholder="Name"
          v-model="venue.name"
          required
        />
      </div>
    </div>
    <div class="field is-pulled-left">
      <label class="label has-text-left">Venue Type (primary)</label>
      <div class="select">
        <select v-model="venue.type">
          <option>Theater</option>
          <option>Live Music</option>
          <option>Burlesque</option>
          <option>Comedy</option>
          <option>Cafe</option>
          <option>Cabaret/Variety</option>
          <option>Event Space</option>
          <option>Mixed Use</option>
        </select>
      </div>
    </div>
    <div class="field is-clearfix">
      <label class="label has-text-left is-clearfix">Address</label>
      <div class="control">
        <input
          class="input is-primary"
          type="text"
          placeholder="Address"
          v-model="venue.address"
          required
        />
      </div>
    </div>
    <div class="field">
      <label class="label has-text-left">City</label>
      <div class="control">
        <input
          class="input is-primary"
          type="text"
          placeholder="City"
          v-model="venue.city"
        />
      </div>
    </div>
    <div class="field is-pulled-left">
      <label class="label has-text-left">State</label>
      <div class="select">
        <select v-model="venue.state">
          <option value="MN">Minnesota</option>
        </select>
      </div>
    </div>
    <div class="field">
      <label class="label has-text-left">Zip Code</label>
      <div class="control">
        <input
          class="input is-primary"
          type="text"
          placeholder="Zip Code"
          v-model="venue.zip"
        />
      </div>
    </div>
    <div class="buttons is-centered">
      <button class="button" @click="cancel">Cancel</button>
      <button class="button" @click="createuser" :disabled="!isValid">
        Sign Up
      </button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "CreateVenue",
  data() {
    return {
      isValid: false,
      venue: {
        name: "",
        type: "",
        address: "",
        city: "",
        state: "",
        zip: ""
      }
    };
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    async createuser() {
      console.log("Creating venue profile");
      var db = firebase.firestore();

      await db
        .collection("venues")
        .add({
          name: this.venue.name,
          type: this.venue.type,
          address: this.venue.address,
          state: this.venue.state,
          zip: this.venue.zip,
          owner: firebase.auth().currentUser.uid
        })
        .then(function(docRef) {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });

      this.$emit("profileCreated", this.user);
      this.$emit("saved", this.user);
    }
  },
  watch: {
    venue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.isValid =
          newValue.name &&
          newValue.type &&
          newValue.address &&
          newValue.state &&
          newValue.zip;
      }
    }
  }
};
</script>
<style scoped>
p.overview {
  margin: 0 0 15px;
}
</style>
