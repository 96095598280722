<template>
  <div>
    <img id="logo" src="/img/gobo-black-square.png" alt="Gobo Logo" />
    <!-- INVITATION ERROR -->
    <div v-if="invitationError">
      <h3>Invitation Not Found</h3>
      <p>
        We're sorry, we can't find that invitation in our system. Our team has
        been notified that something went wrong. Please reach out to the person
        who sent you the invite.
      </p>
    </div>
    <div  v-if="!invitationError">
      <h3>{{ (receivedInvite) ? "Accept your invitation" : "Sign Up for Gobo" }}</h3>
      <div
        class="notification is-danger is-light"
        v-if="errorMessage"
        v-bind:class="{ 'm-fadeIn': errorMessage, 'm-fadeOut': !errorMessage }"
      >
        {{ errorMessage }}
      </div>
      <p class="overview">
        {{ messages[step - 1] }}
      </p>
      <p class="disclaimer">
        By signing up you agree to our <a target="_blank" href="https://www.gobo.show/terms-of-service">terms of service</a>. 
        We charge a 5% fee that includes credit card processing fees. For more information, <a target="_blank" href="https://www.gobo.show/why-gobo">click here</a>. 
      </p>
    </div>
    <div v-show="step == 2">
      <p>We've created your account! Get started selling tickets today.</p>
    </div>
    <div class="container" v-show="step == 3">
      <div class="columns">
        <div class="column is-one-third">
          <label class="label has-text-right"> I am or represent a(n):</label>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-third is-align-items-end">
          <div class="buttons is-right">
            <a
              :class="user.type == 'artist' ? 'button is-primary' : 'button'"
              @click="user.type = 'artist'"
              >Artist or Act</a
            >
          </div>
        </div>
        <div class="column is-two-thirds has-text-left">
          Are you or do you represent a musician, a band, a theater ensemble, a
          podcaster, a storteller, a comedian, or anyone else who performs live?
          Choose this option.
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-third">
          <div class="buttons is-right">
            <a
              :class="user.type == 'venue' ? 'button is-primary' : 'button'"
              @click="user.type = 'venue'"
              >Venue</a
            >
          </div>
        </div>
        <div class="column is-two-thirds has-text-left">
          Do you represent a physical location that hosts artists or acts
          throughout the year? Choose this option.
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-third">
          <div class="buttons is-right">
            <a
              :class="user.type == 'festival' ? 'button is-primary' : 'button'"
              @click="user.type = 'festival'"
              >Festival or Event</a
            >
          </div>
        </div>
        <div class="column is-two-thirds has-text-left">
          Do you represent a one time or periodically recurring event that hosts
          artists? Choose this option.
        </div>
      </div>
    </div>
    <div class="container" v-show="step == 2 && user.type == 'artist'">
      <div class="columns">
        <div class="column is-one-third">
          <label class="label has-text-right">What type of artist?</label>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="buttons is-right">
            <a
              :class="user.type == 'artist' ? 'button is-primary' : 'button'"
              @click="user.type = 'artist'"
              >Solo Artist</a
            >
            <a
              :class="user.type == 'artist' ? 'button is-primary' : 'button'"
              @click="user.type = 'artist'"
              >Band</a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-show="!invitationError && step == 1">
      <div class="field">
        <label class="label">Name:</label>
        <div class="control has-icons-left">
          <input
            class="input is-primary"
            type="text"
            placeholder="Name"
            required
            v-model="user.name"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-user"></i>
          </span>
        </div>
        <p class="help is-danger" v-if="nameChanged && !user.name">
          Please enter a name.
        </p>
      </div>
      <div class="field is-clearfix">
        <label class="label">My email is:</label>
        <div class="control has-icons-left">
          <input
            class="input is-primary"
            type="email"
            required
            placeholder="Email"
            v-model="user.email"
            v-if="!receivedInvite"
          />
          <input
            class="input is-primary"
            type="email"
            required
            placeholder="Email"
            v-model="receivedInvite.email"
            v-if="receivedInvite"
            disabled="true"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
        </div>
        <p class="help is-danger" v-if="!emailValid">
          Please enter a valid email address.
        </p>
      </div>
      <div class="field">
        <label class="label">Password:</label>
        <div class="control">
          <input
            class="input is-primary"
            type="password"
            placeholder="Password"
            required
            v-model="user.password"
          />
        </div>
      </div>
      <div class="field">
        <div class="control">
          <input
            class="input is-primary"
            type="password"
            placeholder="Confirm Password"
            required
            v-model="user.confirmPassword"
          />
        </div>
        <p class="help is-danger" v-if="!passwordsMatch">
          Passwords do not match.
        </p>
      </div>
    </div>

    <div class="container cta" v-if="!invitationError">
      <div class="buttons is-centered">
        <button
          class="button is-primary is-fullwidth"
          @click="createuser"
          :disabled="!isValid"
        >
          Create Account
        </button>
        <button
          class="button"
          @click="createuser"
          :disabled="!isValid"
          v-show="step == 3"
        >
          Sign Up
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  transition: visibility 0s linear 600ms, opacity 600ms, height 600ms,
    padding-top 600ms;
}
.m-fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 600ms, height 600ms,
    padding-top 600ms, padding-bottom 600ms;
}
.disclaimer {
  font-size: 0.8em;
  line-height: 1.5em;
  color: $oops-red;
}
</style>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import store from "../store";
import { mapGetters } from "vuex";

export default {
  name: "SignUp",
  props: {
    invitationId: String,
    invitationError: Boolean
  },
  data() {
    return {
      step: 1,
      isValid: false,
      nameChanged: false,
      errorMessage: "",
      emailValid: true,
      passwordsMatch: true,
      messages: [
        "Sign up is easy. First let's create an account.",
        "That's awesome. Tell us a little bit about yourself (you can fill out a full profile later).",
        "One last thing: how do you want to sign in?"
      ],
      user: {
        email: "",
        password: "",
        confirmPassword: "",
        name: "",
        type: ""
      }
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      receivedInvite: "receivedInvite"
    })
  },
  async mounted() {
    if (this.invitationId) {
      console.log("yoyo");
      await store.dispatch("findInvite", this.invitationId);
      if (this.receivedInvite) this.user.email = this.receivedInvite.email;
      else this.invitationError = true;
    }
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    async createuser() {
      console.log("Authenticating", this.user.email);
      const email = (this.receivedInvite) ? this.receivedInvite.email : this.user.email;
      // create user
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email, this.user.password)
        .then(function() {
          var user = firebase.auth().currentUser;
          console.log("We have user", user);
          user.sendEmailVerification();
          //this.errorCode = "";
        })
        .catch(error => {
          // Handle Errors here.
          //var errorCode = error.code;
          //this.errorMessage = error.message;
          console.log(
            "Authentication error " + error + ": " // + this.errorMessage
          );
        });

      var user = firebase.auth().currentUser;
      if (user) {
        user.sendEmailVerification();
        this.errorCode = "";

        // Update profile with user name
        user
          .updateProfile({
            displayName: this.user.name
            //photoURL: "https://example.com/jane-q-user/profile.jpg"
          })
          .then(function() {
            // Update successful.
          })
          .catch(function(error) {
            console.log(
              "Error updating profile " + error.code + ": " + error.errorMessage
            );
          });

        // set profile object
        var db = firebase.firestore();
        let newuser = {
            name: this.user.name,
            uid: firebase.auth().currentUser.uid
          };
        if (this.receivedInvite) newuser.invitationId = this.receivedInvite.id;
        db.collection("users")
          .doc(user.uid)
          .set(newuser)
          .then(function(docRef) {
            console.log("Document written with ID: " + JSON.stringify(docRef));
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
          });
        this.$emit("saved", this.user);
      }
    },
    validEmail(email) {
      //var re = new RegExp('/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/');
      if (email) return true; // re.test(email);
    }
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.name) this.nameChanged = true;
        if (newValue.password && newValue.confirmPassword) {
          this.passwordsMatch = newValue.password == newValue.confirmPassword;
        } else this.passwordsMatch = true;
        if (newValue.email) this.emailValid = this.validEmail(newValue.email);
        else this.emailValid = true;
        this.isValid =
          this.passwordsMatch &&
          newValue.confirmPassword &&
          newValue.password &&
          ((this.emailValid && newValue.email) || (this.receivedInvite && this.receivedInvite.email));
      }
    }
  }
};
</script>

<style scoped>
.cta {
  margin-top: 1.5em;
}
</style>
