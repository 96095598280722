<template>
    <div>
      <h1>Let's Try Another Date</h1>
      <EventPreview :event="event"
            :selectedDate="dateSummary"
            :venue="venue" />
      <div class="orderline">
        <div class="orderlineitem">{{ order.firstName }} {{ order.lastName }}</div>
        <div class="orderlineitem">{{ order.email }}</div>
        <div class="orderlineitem">{{ order.quantity }} - {{ order.tierName }}</div>
      </div>
      <div>
        <p>How many tickets do you want to move?</p>
      </div>
      <div class="ticketsline">
        <div :class="{ selected: quantity == index }" v-for="index in order.quantity" :key="index" class="ticketslineitem" @click="quantity = index">{{ index }}</div>
      </div>
      <div>
        <p>Which date do you want to move to?</p>
        <DateSlider
            :dates="eventDates"
            :visible="true"
            :displayAllDates="true"
            @setDate="setDate"
            v-if="
              eventDates.length > 1 &&
                (!event.eventType || event.eventType != 'videoOnDemand')
            "
          />
      </div>
      <div v-if="selectedDate && selectedDate.start.toDate() < Date.now()">
        <p class="error">This date is in the past. Do you want to the tickets to this date?</p>
      </div>
      <div class="columns">
        <div class="column">
          <button class="button is-fullwidth" @click="cancel">
            Cancel
          </button>
        </div>
        <div class="column">
          <button class="button is-fullwidth is-primary" :disabled="selectedDate == null || quantity == 0" @click="move">
            Move
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import store from "@/store";
  import firebase from "firebase/app";
  import EventPreview from "@/components/event-preview";
  import DateSlider from "@/components/date-slider-new";
  
  export default {
    name: "Move",
    data() {
      return {
        quantity: 0,
        selectedDate: null,
      };
    },
    computed: {
      ...mapGetters({
        dateSummary: "dateSummary",
        event: "event",
        venue: "venue",
        order: "order",
        eventDates: "eventDates",
      })
    },
    components: {
        EventPreview,
        DateSlider
    },
    async created() {
      console.log("Get price tiers.");
      await store.dispatch("fetchPriceTiers");
    },
    methods: {
      async add() {
        console.log("here we go");
      },
      setDate(date) {
        console.log("tricky bastard");
        this.selectedDate = date;
      },
      cancel() {
        this.$emit("canceled");
      },
      async move() {
        const status = (this.order.quantity == this.quantity) ? "moved" : "partiallyMoved";
        const remaining = this.order.quantity - this.quantity;
        const newRes = this.order;
        newRes.quantity = this.quantity
        console.log("moving", status, remaining);
        var db = firebase.firestore();
        var resRef = await db
          .collection("events")
          .doc(this.event.id)
          .collection("userData")
          .doc("audienceReport")
          .collection("dates")
          .doc(this.selectedDate.id)
          .collection("audience")
          .add(newRes)
          .catch(function(error) {
            console.error("Error moving patron: ", error);
          });
        resRef.update({ id: resRef.id });
        db.collection("events")
          .doc(this.event.id)
          .collection("userData")
          .doc("audienceReport")
          .collection("dates")
          .doc(this.dateSummary.dateId)
          .collection("audience")
          .doc(this.order.id)
          .update({ status: status, quantity: remaining, movedTo: this.selectedDate.id })
          .catch(function(error) {
            console.error("Error marking patron moved: ", error);
          });
        this.$emit("canceled");
      }
    },
    mounted() {
        console.log('move mounted');
        this.quantity = this.order.quantity;
    }
  };
  </script>
  
<style lang="scss">
@import "@/assets/variables.scss";
  .orderline, .ticketsline {
    border-bottom: 1px solid $gray6;
    border-top: 1px solid $gray6;
    display: flex;
  }
  .orderline {
    padding: 20px 0;
    justify-content: space-between;
  }
  .ticketsline {
    padding: 7px 0;
    justify-content: center;
    align-items: center;
  }
  .ticketslineitem {
    border: 1px solid $gray5;
    border-radius: 6px;
    width: 50px;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-right: 4px;
    font-weight: bold;
    font-size: 14px;
  }
  .ticketslineitem:hover {
    border: 1px solid $gray3;
  }
  .ticketslineitem.selected {
    background-color: $active-green;
    border: 1px solid $active-green;
    color: white;
  }
  p {
    font: $family-secondary;
    font-weight: bold;
    font-size: 18px;
  }
  </style>
  