import { render, staticRenderFns } from "./create-venue-profile.vue?vue&type=template&id=72a4676c&scoped=true"
import script from "./create-venue-profile.vue?vue&type=script&lang=js"
export * from "./create-venue-profile.vue?vue&type=script&lang=js"
import style0 from "./create-venue-profile.vue?vue&type=style&index=0&id=72a4676c&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a4676c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/gobo-app/gobo-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72a4676c')) {
      api.createRecord('72a4676c', component.options)
    } else {
      api.reload('72a4676c', component.options)
    }
    module.hot.accept("./create-venue-profile.vue?vue&type=template&id=72a4676c&scoped=true", function () {
      api.rerender('72a4676c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/create-venue-profile.vue"
export default component.exports