<template>
  <div class="box">
    <h3>Artist Profile</h3>
    <p class="overview">
      You're almost there! Tell us a little about you and we'll get you started
      booking gigs and selling tickets. If you represent multiple acts, don't
      worry, you'll have a chance to add those later.
    </p>
    <div class="field is-pulled-left">
      <label class="label has-text-left">Artist type</label>
      <div class="select">
        <select v-model="artist.type">
          <option>Theater</option>
          <option>Improv</option>
          <option>Storyteller/Spoken Word Artist</option>
          <option>Podcaster</option>
          <option>Musician</option>
          <option value="">Music Theater</option>
          <option>Band</option>
          <option>Choir</option>
          <option>Comedian</option>
          <option>Magician</option>
          <option>Other</option>
        </select>
      </div>
    </div>
    <div class="field">
      <label class="label has-text-left"
        >Group or Ensemble Name (if applicable)</label
      >
      <div class="control">
        <input
          class="input is-primary"
          type="text"
          placeholder="Name"
          v-model="artist.name"
        />
      </div>
    </div>
    <div class="field is-clearfix">
      <label class="label has-text-left is-clearfix">Location</label>
      <div class="control">
        <input
          class="input is-primary"
          type="text"
          placeholder="Location"
          v-model="artist.location"
        />
      </div>
    </div>
    <div class="field is-clearfix">
      <label class="label has-text-left is-clearfix">Description</label>
      <div class="control">
        <input
          class="input is-primary"
          type="textarea"
          placeholder="Tell us about yourself."
          v-model="artist.description"
        />
      </div>
    </div>
    <div class="buttons is-centered">
      <button class="button" @click="cancel">Cancel</button>
      <button class="button" @click="createuser" :disabled="!isValid">
        Sign Up
      </button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "CreateArtist",
  data() {
    return {
      isValid: false,
      artist: {
        type: "",
        name: "",
        location: "",
        description: ""
      }
    };
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    async createuser() {
      console.log("Creating artist profile");
      var db = firebase.firestore();

      await db
        .collection("artists")
        .add({
          name: this.artist.name,
          type: this.artist.type,
          location: this.artist.location,
          description: this.artist.description,
          owner: firebase.auth().currentUser.uid
        })
        .then(function(docRef) {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });

      this.$emit("profileCreated", this.user);
    }
  },
  watch: {
    artist: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.isValid =
          newValue.name &&
          newValue.type &&
          newValue.location &&
          newValue.description;
      }
    }
  }
};
</script>
<style scoped>
p.overview {
  margin: 0 0 15px;
}
</style>
