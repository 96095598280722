var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", [_vm._v("Add Comp")]),
    _c("div", { staticClass: "field is-clearfix" }, [
      _c("label", { staticClass: "label" }, [_vm._v("First Name:")]),
      _c("div", { staticClass: "control" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.firstName,
              expression: "firstName"
            }
          ],
          staticClass: "input is-primary",
          attrs: { type: "email", required: "", placeholder: "First Name" },
          domProps: { value: _vm.firstName },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.firstName = $event.target.value
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "field is-clearfix" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Last Name:")]),
      _c("div", { staticClass: "control" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.lastName,
              expression: "lastName"
            }
          ],
          staticClass: "input is-primary",
          attrs: { type: "text", required: "", placeholder: "Last Name" },
          domProps: { value: _vm.lastName },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.lastName = $event.target.value
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "field is-clearfix" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Email:")]),
      _c("div", { staticClass: "control has-icons-left" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          staticClass: "input is-primary",
          attrs: { type: "email", required: "", placeholder: "Email" },
          domProps: { value: _vm.email },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.email = $event.target.value
            }
          }
        }),
        _vm._m(0)
      ]),
      !_vm.emailValid
        ? _c("p", { staticClass: "help is-danger" }, [
            _vm._v(" Please enter a valid email address. ")
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "field is-clearfix" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Quantity:")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.quantity,
            expression: "quantity",
            modifiers: { number: true }
          }
        ],
        staticClass: "input",
        attrs: { type: "number", required: "" },
        domProps: { value: _vm.quantity },
        on: {
          input: function($event) {
            if ($event.target.composing) return
            _vm.quantity = _vm._n($event.target.value)
          },
          blur: function($event) {
            return _vm.$forceUpdate()
          }
        }
      })
    ]),
    _c("div", { staticClass: "field is-clearfix" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Note:")]),
      _c("div", { staticClass: "control" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.note,
              expression: "note"
            }
          ],
          staticClass: "input is-primary",
          attrs: { type: "text", required: "", placeholder: "Note" },
          domProps: { value: _vm.note },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.note = $event.target.value
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "buttons is-centered" }, [
      _c(
        "button",
        { staticClass: "button is-fullwidth", on: { click: _vm.cancel } },
        [_vm._v("Cancel")]
      ),
      _c(
        "button",
        {
          staticClass: "button is-fullwidth is-primary",
          on: { click: _vm.add }
        },
        [_vm._v(" Add Comp ")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-envelope" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }