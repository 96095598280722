import firebase from "firebase/app";
import Vue from "vue";
//import "firebase/firestore";

export const state = {
  venue: null,
  venues: []
};

export const mutations = {
  SET_VENUE(state, venue) {
    state.venue = venue;
  },
  SET_VENUES(state, venues) {
    console.log("venues", venues);
    state.venues = venues;
  },
  ADD_NEW_VENUE_PROFILE(state, venue) {
    state.venues.push(venue);
  },
  ADD_VENUE_PROFILE(state, venue) {
    console.log("adding venue profile");
    if (
      state.venues &&
      state.venues.length > 0 &&
      state.venues[state.venues.length - 1].name == ""
    )
      Vue.set(state.venues, state.venues.length - 1, venue);
    else state.venues.push(venue);
  }
};

export const actions = {
  async fetchVenue({ commit }, venueId) {
    var db = firebase.firestore();
    var docRef = db.collection("venues").doc(venueId);
    var doc = await docRef.get();

    if (doc.exists) {
      commit("SET_VENUE", doc.data());
    } else {
      console.log("Could not find venue " + venueId);
    }
  },
  async fetchMyVenues({ commit }, userId) {
    console.log("fetching venues", userId);
    var db = firebase.firestore();
    var docRef = db.collection("venues").where("owners." + userId, "!=", "");
    var docs = await docRef.get();

    if (!docs.empty) {
      commit(
        "SET_VENUES",
        docs.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find venues.");
    }
  },
  async setVenue({ commit }, venue) {
    var db = firebase.firestore();

    // Does the venue exist already?
    const doc = await db
      .collection("venues")
      .doc(venue.id)
      .get();
    if (doc.exists) commit("SET_VENUE", doc.data());
    // Apparently not. Add it.
    else {
      await db
        .collection("venues")
        .doc(venue.id)
        .set(venue)
        .catch(function(error) {
          console.error("Error updating venue: ", error);
        });

      console.log("Venue Recieved", venue);
      commit("SET_VENUE", venue);
    }
  },
  addNewVenueProfile({ commit }) {
    commit("ADD_NEW_VENUE_PROFILE", { name: "", tax: "remit" });
  },
  async addVenueProfile({ commit }, venue) {
    var db = firebase.firestore();
    // Get the doc ref
    const venueRef = await db.collection("venues").doc(venue.id);

    // Does the venue exist already?
    const doc = await venueRef.get();
    if (doc.exists) {
      var venueData = doc.data();
      // Has it been claimed?
      if (venueData.owners)
        commit("ADD_VENUE_PROFILE", {
          id: venue.id,
          name: venue.name,
          error: "Venue already cliamed by another Gobo user."
        });
      else {
        await venueRef.update({ owner: venue.owner, owners: venue.owners });
        const claimedVenue = (await venueRef.get()).data();
        commit("ADD_VENUE_PROFILE", claimedVenue);
      }
    } else {
      await db
        .collection("venues")
        .doc(venue.id)
        .set(venue)
        .catch(function(error) {
          console.error("Error updating venue: ", error);
        });

      console.log("Venue Added & Claimed", venue);
      commit("ADD_VENUE_PROFILE", venue);
    }
  },
  async saveVenueProfileImage({ commit }, venue) {
    console.log("saving a venue image");
    var db = firebase.firestore();
    var venueRef = await db.collection("venues").doc(venue.id);
    await venueRef
      .update({
        image: venue.image
      })
      .catch(function(error) {
        console.error("Error saving document: ", error);
      });

    var savedProfile = await venueRef.get();
    commit("SET_VENUE", savedProfile.data());
  },
  async clearVenue({ commit }) {
    commit("SET_VENUE", null);
  }
};

export const getters = {
  venue(state) {
    return state.venue;
  },
  venues(state) {
    return state.venues;
  }
};
