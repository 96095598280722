<template>
  <div>
    <h1>Add Comp</h1>
    <div class="field is-clearfix">
      <label class="label">First Name:</label>
      <div class="control">
        <input
          class="input is-primary"
          type="email"
          required
          placeholder="First Name"
          v-model="firstName"
        />
      </div>
    </div>
    <div class="field is-clearfix">
      <label class="label">Last Name:</label>
      <div class="control">
        <input
          class="input is-primary"
          type="text"
          required
          placeholder="Last Name"
          v-model="lastName"
        />
      </div>
    </div>
    <div class="field is-clearfix">
      <label class="label">Email:</label>
      <div class="control has-icons-left">
        <input
          class="input is-primary"
          type="email"
          required
          placeholder="Email"
          v-model="email"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-envelope"></i>
        </span>
      </div>
      <p class="help is-danger" v-if="!emailValid">
        Please enter a valid email address.
      </p>
    </div>
    <div class="field is-clearfix">
      <label class="label">Quantity:</label>
      <input class="input" type="number" required v-model.number="quantity" />
    </div>
    <div class="field is-clearfix">
      <label class="label">Note:</label>
      <div class="control">
        <input
          class="input is-primary"
          type="text"
          required
          placeholder="Note"
          v-model="note"
        />
      </div>
    </div>
    <div class="buttons is-centered">
      <button class="button is-fullwidth" @click="cancel">Cancel</button>
      <button class="button is-fullwidth is-primary" @click="add">
        Add Comp
      </button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { mapGetters } from "vuex";

export default {
  name: "addcomp",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      quantity: 1,
      note: "",
      emailValid: true
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      event: "event",
      dateSummary: "dateSummary"
    })
  },
  methods: {
    async add() {
      var db = firebase.firestore();
      await db
        .collection("events")
        .doc(this.event.id)
        .collection("userData")
        .doc("audienceReport")
        .collection("dates")
        .doc(this.dateSummary.dateId)
        .collection("audience")
        .add({
          checkedin: 0,
          discountCode: this.note,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          price: 0,
          quantity: this.quantity,
          status: "paid",
          tierName: "Comp",
          total: 0
        }).catch(function(error) {
          console.error("Error adding document: ", error);
        });
      this.$emit("compAdded");
    },
    cancel() {
      this.firstName = this.lastname = this.note = "";
      this.quantity = 1;
      this.$emit("canceled");
    }
  }
};
</script>

<style></style>
