import firebase from "firebase/app";
//import "firebase/firestore";

export const state = {
  foundArtists: [],
  artist: null
};

export const mutations = {
  SET_FOUND_ARTISTS(state, artists) {
    state.foundArtists = artists;
  },
  SET_ARTIST(state, artist) {
    state.artist = artist;
  }
};

export const actions = {
  async findArtists({ commit }, search) {
    if (search == "") commit("SET_FOUND_ARTISTS", []);
    else {
      var db = firebase.firestore();
      var docs = await db
        .collection("performers")
        .where("name", ">=", search)
        .where("name", "<=", search + "\uf8ff")
        .limit(5)
        .get();

      if (!docs.empty) {
        // console.log("docs", docs)
        commit(
          "SET_FOUND_ARTISTS",
          docs.docs.map(doc => doc.data())
        );
      } else {
        console.log("Could not find any artists with this search.", search);
        commit("SET_FOUND_ARTISTS", []);
      }
    }
  },
  async clearFoundArtists({ commit }) {
    commit("SET_FOUND_ARTISTS", []);
  },
  async createArtistProfile({ commit }, profile) {
    console.log("creating a new profile", profile);
    var db = firebase.firestore();
    var newDocRef = await db.collection("performers").doc();
    await newDocRef
      .set({
        id: newDocRef.id,
        name: profile.name
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });

    var newprofile = await newDocRef.get();
    commit("SET_ARTIST", newprofile.data());
    return newprofile.data();
  },
};

export const getters = {
  foundArtists(state) {
    return state.foundArtists;
  }
};
