var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("img", {
      attrs: { id: "logo", src: "/img/gobo-black-square.png", alt: "Gobo Logo" }
    }),
    _c("h3", [_vm._v("Sign Out")]),
    _c("p", [_vm._v("Are you sure you want to sign out?")]),
    _c("div", { staticClass: "buttons is-centered" }, [
      _c(
        "button",
        { staticClass: "button is-fullwidth", on: { click: _vm.cancel } },
        [_vm._v("Cancel")]
      ),
      _c(
        "button",
        {
          staticClass: "button is-fullwidth is-primary",
          on: { click: _vm.logout }
        },
        [_vm._v("Sign Out")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }