<template>
  <div>
    <h1>Add Walkup</h1>
    <Tierpicker
      :tiers="priceTiers"
      :selectedDate="true"
      :maxSale="dateSummary.inventory"
    />
    <div class="columns">
      <div class="column">
        <button class="button is-fullwidth is-primary" @click="add('Cash')">
          Cash
        </button>
      </div>
      <div class="column">
        <button class="button is-fullwidth is-primary" @click="add('Credit')">
          Credit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import firebase from "firebase/app";
import Tierpicker from "@/components/tierpicker";

export default {
  name: "Walkup",
  data() {
    return {
      quantity: 2
    };
  },
  computed: {
    ...mapGetters({
      priceTiers: "priceTiers",
      dateSummary: "dateSummary",
      event: "event"
    })
  },
  components: {
    Tierpicker
  },
  async created() {
    console.log("Get price tiers.");
    await store.dispatch("fetchPriceTiers");
  },
  methods: {
    async add(method) {
      var db = firebase.firestore();

      this.priceTiers.forEach(tier => {
        if (tier.quantity) {
          let price = 0;
          if (tier.feeType == "fixed" || tier.feeType == "sliding")
            price = tier.price;
          const lineItem = {
            checkedin: tier.quantity,
            firstName: method,
            lastName: "Walkup",
            tierId: tier.id,
            tierName: tier.name,
            quantity: tier.quantity,
            price: price,
            total: price * tier.quantity,
            status: "checkedin"
          };
          db.collection("events")
            .doc(this.event.id)
            .collection("userData")
            .doc("audienceReport")
            .collection("dates")
            .doc(this.dateSummary.dateId)
            .collection("audience")
            .add(lineItem)
            .catch(function(error) {
              console.error("Error adding walkup: ", error);
            });
          tier.quantity = 0;
        }
        this.$emit("walkupAdded");
      });
    }
  }
};
</script>

<style></style>
