<template>
  <div id="app">
    <div id="hero" v-bind:class="{ hero: !user.loggedIn }">
      <nav
        id="nav"
        class="navbar"
        role="navigation"
        aria-label="main navigation"
      >
        <div class="navbar-brand">
          <router-link :to="{ name: 'events' }"
            ><img class="navlogo" src="/img/gobo-logo-black.svg" alt=""
          /></router-link>
          <a
            role="button"
            class="navbar-burger"
            :class="{ 'is-active': burgerToggle }"
            aria-label="menu"
            aria-expanded="false"
            @click="burgerToggle = !burgerToggle"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div class="navbar-menu" :class="{ 'is-active': burgerToggle }">
          <div id="center" v-if="user && user.loggedIn" class="navbar-item is-hidden-mobile">
            <router-link class="navbar-item" :to="{ name: 'events' }"
              >My Events</router-link
            >
            <router-link class="navbar-item" to="/help">Help</router-link>
          </div>
          <div id="center" v-if="!user || !user.loggedIn" class="navbar-item is-hidden-mobile">
            <a class="navbar-item" href="https://tix.gobo.show">Upcoming Events</a>
            <router-link class="navbar-item" to="/help">Help</router-link>
          </div>
          <div class="navbar-end" @click="burgerToggle = !burgerToggle">
            <div class="navbar-item" v-if="!user || !user.loggedIn">
              <a class="navbar-item" @click="login = true">Sign In</a>
            </div>
            <div class="navbar-item shaded" v-if="user && user.loggedIn">
              <a @click="createEvent" class="button is-dark is-flex-mobile"
                >Create Event</a
              >
            </div>
            <div class="navbar-item shaded" v-if="user && user.loggedIn && user.festivalManager">
              <a @click="createFestival" class="button is-dark is-flex-mobile"
                >Create Festival</a
              >
            </div>
            <router-link
              class="navbar-item is-hidden-tablet"
              :to="{ name: 'events' }"
              >My Events</router-link
            >
            <router-link class="navbar-item is-hidden-tablet" to="/help"
              >Help</router-link
            >
            <a class="navbar-item is-hidden-tablet" @click="logout = true"
              >Sign Out</a
            >
            <div
              class="navbar-item is-hidden-mobile"
              v-if="user && user.loggedIn"
            >
              <Avatar />
              <div class="navbar-item has-dropdown is-hoverable">
                <router-link class="navbar-link" to="/profile">{{
                  user.name
                }}</router-link>
                <div class="navbar-dropdown is-boxed">
                  <router-link class="navbar-item" to="/profile"
                    >Account Settings</router-link
                  >
                  <a class="navbar-item" @click="logout = true">
                    Sign Out
                  </a>
                </div>
              </div>
            </div>
            <div class="buttons" v-if="!user || !user.loggedIn">
              <a class="button is-dark" @click="signup = true">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </nav>

      <router-view @signmeup="signmeup" @addcomp="addcomp" @walkup="walkup" @sellTickets="sellTickets" @pass="pass" @addpass="newpass" />
      <div v-if="!user || !user.loggedIn"></div>
      <div
        class="modal"
        :class="{
          'is-active':
            createArtist ||
            createVenue ||
            signup ||
            login ||
            logout ||
            showConfirm ||
            addingcomp ||
            walkingup ||
            applyingpass ||
            addingpass ||
            userInvite ||
            buyTickets
        }"
      >
        >
        <div class="modal-background"></div>
        <div class="modal-content" :class="{ buyTickets: buyTickets }">
          <div id="modalwrapper" class="box">
            <div id="modalheader" v-if="!buyTickets">
              <a id="modalclose" @click="canceled()" v-if="!invitationId"
                ><i class="fas fa-times"></i
              ></a>
            </div>
            <div class="modal-inner-wrapper" :class="{ checkout: walkingup }">
              <SignUp
                @canceled="canceled"
                @saved="createduser"
                v-if="signup"
                :invitationId="invitationId"
                :invitationError="invitationError"
              />
              <LogIn @canceled="canceled" @loggedin="loggedin" v-if="login" />
              <LogOut
                @canceled="canceled"
                @loggedout="loggedout"
                v-if="logout"
              />
              <CreateArtist
                @canceled="canceled"
                @profileCreated="profileCreated"
                v-if="createArtist"
              />
              <CreateVenue
                @canceled="canceled"
                @profileCreated="profileCreated"
                v-if="createVenue"
              />
              <Confirm
                :header="confirmation.header"
                :message="confirmation.message"
                :cta="confirmation.cta"
                v-if="showConfirm && confirmation"
                @canceled="canceled"
              />
              <AddComp
                v-if="addingcomp"
                @canceled="canceled"
                @compAdded="canceled"
              />
              <Walkup
                v-if="walkingup"
                @canceled="canceled"
                @walkupAdded="canceled"
              />
              <ApplyPass
                v-if="applyingpass"
                @canceled="canceled"
                @passApplied="canceled"
              />
              <AddPass
                v-if="addingpass"
                @canceled="canceled"
                @passApplied="canceled"
              />
              <BuyTickets v-if="buyTickets" @canceled="canceled" />
              <InviteUser v-if="userInvite" @canceled="canceled" />
            </div>
            <div id="modalfooter"></div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <nav
        id="footer-nav-top"
        class="navbar is-hidden-mobile"
        role="navigation"
        aria-label="secondary navigation"
      >
        <div class="navbar-brand">
          <img class="navlogo" src="/img/gobo-logo-white.svg" alt="Gobo" />
        </div>
        <div class="navbar-end">
          <div class="navbar-item" v-if="!user || !user.loggedIn">
            <a class="navbar-item" @click="login = true">Sign In</a>
          </div>
          <div class="buttons" v-if="!user || !user.loggedIn">
            <a class="button is-light" @click="signmeup('footer')">
              Get Started
            </a>
          </div>
        </div>
      </nav>
      <div class="social is-hidden-tablet">
        <a href="https://twitter.com/goboshow/"
          ><span class="icon is-large">
            <i class="fab fa-2x fa-twitter"></i> </span
        ></a>
        <a href="https://www.facebook.com/Goboshow/"
          ><span class="icon is-large">
            <i class="fab fa-2x fa-facebook-f"></i> </span
        ></a>
        <a href="https://www.instagram.com/goboshow/"
          ><span class="icon is-large">
            <i class="fab fa-2x fa-instagram"></i> </span
        ></a>
      </div>
      <nav class="level is-mobile is-hidden-tablet">
        <div class="level-left">
          <img class="navlogo" src="/img/gobo-logo-white.svg" alt="Gobo" />
        </div>
        <div class="level-right"><div id="copy">&copy;{{ new Date().getFullYear() }} Gobo Show, Inc</div></div>
      </nav>
      <div class="content has-text-centered is-hidden-mobile">
        <div id="spacer"></div>
        <nav
          id="footer-nav"
          class="navbar"
          role="navigation"
          aria-label="secondary navigation"
        >
          <div class="navbar-brand">
            <div id="copy">&copy;{{ new Date().getFullYear() }} Gobo Show, Inc</div>
          </div>
          <div id="center" class="navbar-item is-hidden-mobile">
            <a class="navbar-item" href="https://www.gobo.show/about-us"
              >Our Story</a
            >
            <router-link class="navbar-item" to="/help">Help</router-link>
            <a class="navbar-item" href="https://www.gobo.show/privacy-notice"
              >Privacy Notice</a
            >
            <router-link v-if="false" class="navbar-item" to="/about"
              >Terms of Service</router-link
            >
            <div class="navbar-end">
              <div class="navbar-item">
                <a href="https://twitter.com/goboshow"
                  ><img
                    class="socialicon"
                    src="/img/icons/Twitter.svg"
                    alt="Follow us on Twitter"
                /></a>
              </div>
              <div class="navbar-item">
                <a href="https://www.facebook.com/Goboshow/">
                  <img
                    class="socialicon"
                    src="/img/icons/Facebook.svg"
                    alt="Follow us on Facebook"
                /></a>
              </div>
              <div class="navbar-item">
                <a href="https://www.instagram.com/Goboshow/">
                  <img
                    class="socialicon"
                    src="/img/icons/Instagram.svg"
                    alt="Follow us on Instagram"
                /></a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
.socialicon {
  height: 20px;
}
#hero.hero {
  background-image: url("/img/hero.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 700px;
}
#nav {
  background: none;
  padding: 1.5em 2em 0;
}
#center {
  flex: 1;
  justify-content: center;
  padding-left: 130px;
}
#copy {
  font-size: 0.75em;
  padding: 1rem 0.75rem;
}
#spacer {
  min-height: 16rem;
}
</style>

<script>
import store from "@/store";
import SignUp from "@/components/signup";
import LogIn from "@/components/login";
import LogOut from "@/components/logout";
import Avatar from "@/components/avatar";
import CreateArtist from "@/components/create-artist-profile";
import CreateVenue from "@/components/create-venue-profile";
import Confirm from "@/components/confirm";
import AddComp from "@/components/add-comp";
import Walkup from "@/components/walkup";
import ApplyPass from "@/components/apply-pass";
import AddPass from "@/components/add-pass";
import BuyTickets from "@/components/buy-tickets";
import InviteUser from "@/components/invite-user";
import { mapGetters } from "vuex";

export default {
  name: "gobo",
  data() {
    return {
      burgerToggle: false,
      signup: true,
      login: false,
      logout: false,
      createArtist: false,
      createVenue: false,
      addingcomp: false,
      walkingup: false,
      applyingpass: false,
      addingpass: false,
      buyTickets: false,
      invitationId: null,
      invitationError: false
    };
  },
  components: {
    SignUp,
    LogIn,
    LogOut,
    CreateArtist,
    CreateVenue,
    Avatar,
    Confirm,
    AddComp,
    Walkup,
    ApplyPass,
    AddPass,
    BuyTickets,
    InviteUser
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      profiles: "profiles",
      showConfirm: "showConfirm",
      confirmation: "confirmation",
      userInvite: "userInvite",
      receivedInvite: "receivedInvite"
    })
  },
  async mounted() {
    this.signup = !this.user;
    if (this.$route.params.invitationId) {
      this.invitationId = this.$route.params.invitationId;
      this.signup = true;
      console.log("yoyo");
      await store.dispatch("findInvite", this.invitationId);
      if (!this.receivedInvite) this.invitationError = true;
    }

    // listen for login
    // you will need to modify this if profiles get modified in the future.
    store.subscribe(mutation => {
      console.log("route name", this.$route.name);
      if (mutation.type === "SET_USER_PROFILES" && this.$route.name == "home") {
        if (this.profiles && this.profiles.length) {
          if (this.$route.name != "createEvent")
            this.$router.push({ name: "createEvent" });
        } else this.$router.push({ name: "profile" });
      }
    });
  },
  methods: {
    canceled() {
      this.signup = this.login = this.logout = this.createArtist = this.createVenue = this.addingcomp = this.walkingup = this.buyTickets = this.applyingpass = this.addingpass = false;
      if (this.userInvite) store.dispatch("clearInvite");
      this.$analytics.logEvent("modal_dismissed");
    },
    createduser() {
      this.signup = false;
      //if (newuser.type == "artist") this.createArtist = true;
      //else this.createVenue = true;
      this.$router.push({ name: "profile" });
      this.$analytics.logEvent("account_created");
    },
    loggedin() {
      this.login = false;
      this.$analytics.logEvent("logged_in");
    },
    loggedout() {
      this.logout = false;
      this.$router.replace("/");
      this.$analytics.logEvent("logged_out");
    },
    profileCreated() {
      this.createVenue = this.createArtist = false;
    },
    signmeup(source) {
      this.signup = true;
      this.$analytics.logEvent("signup_opened", { source: source });
    },
    addcomp() {
      this.addingcomp = true;
    },
    walkup() {
      this.walkingup = true;
    },
    pass() {
      this.applyingpass = true;
    },
    newpass() {
      this.addingpass = true;
    },
    sellTickets() {
      this.buyTickets = true;
    },
    createEvent() {
      store.dispatch("newEvent");
      this.$router.push({ name: "createEvent" });
    },
    createFestival() {
      store.dispatch("newFestivalSeason");
      this.$router.push({ name: "createFestival" });
    }
  }
};
</script>
