<template>
  <div class="field">
    <div class="control has-icons-left" :class="{ 'has-icons-right': isValid }">
      <input
        type="email"
        class="input"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="emailString"
        @keyup="validate"
      />
      <span class="icon is-left"><i class="fas fa-envelope"></i></span>
      <span v-if="isValid" class="icon is-small is-right">
        <i class="fas fa-check"></i>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    valid: Boolean,
    email: String,
    disabled: Boolean,
    placeholder: { default: "Email", type: String }
  },
  emits: ["update:valid", "update:email"],
  data() {
    return {
      isValid: false,
      emailString: this.email
    };
  },
  methods: {
    validate() {
      this.isValid = /^[^@]+@\w+(\.\w+)+\w$/.test(this.emailString);
      this.$emit("update:valid", this.isValid);
      this.$emit("update:email", this.emailString);
    }
  }
};
</script>
