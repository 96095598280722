<template>
  <div id="herocontent" class="box is-align-content-center">
    <h2 class="has-text-centered mt-6 padme">
      Ticket Fees Suck.<br>
      Gobo Doesn't.
    </h2>
    <p class="has-text-centered mt-6 padme">
      Gobo is a ticketing solution built by artists and venue managers to keep
      ticketing easy and affordable. We charge a flat 5% fee that includes credit card fees,
      and give you tools to help run your shows and build your audience.
    </p>
    <div class="buttons is-centered mt-6">
      <a class="button is-primary" @click="signmeup">
        Start Selling Tickets Now
      </a>
    </div>
  </div>
</template>

<style lang="scss">
.padme {
  padding: 0 15%;
}
#herocontent {
  background: none;
  height: 75%;
  border: none;
  box-shadow: none;
}
#herocontent > h2 {
  font-family: "Rubik";
  font-weight: bold;
  text-transform: uppercase;
  padding: 7% 25% 0%;
  font-size: 1.5em;
}
</style>

<script>
export default {
  name: "Home",
  props: ["invitationId"],
  mounted() {
    console.log("invite", this.invitationId);
  },
  methods: {
    signmeup() {
      this.$emit("signmeup");
    }
  }
};
</script>
