import firebase from "firebase/app";
import "firebase/firestore";
// Moment.js
import moment from "moment";
import Bugsnag from "@bugsnag/js";

const blankdate = {
  id: null,
  eventId: null,
  startDate: null,
  startTime: null,
  endTime: null
};
const blanktier = {
  id: null,
  eventId: null,
  name: "",
  capacity: null,
  price: null,
  minPrice: null,
  maxPrice: null,
  selectDates: false,
  selectedDates: [],
  quantityType: "flexible"
};

export const state = {
  events: [],
  event: null,
  eventDates: [
    { id: null, eventId: null, startDate: null, startTime: null, endTime: null }
  ],
  priceTiers: [],
  newtier: null,
  settings: {},
  analytics: null
};

// Listen to changes made to the event
var eventListener = null;


export const mutations = {
  ADD_EVENT(state, event) {
    state.events.push(event);
  },
  SET_EVENTS(state, events) {
    state.events = events;
  },
  SET_EVENT(state, event) {
    console.log("event is getting set", event);
    state.event = event;
  },
  EVENT_REMOVED(state, event) {
    console.log("removing event");
    state.events = state.events.filter(item => item != event);
  },
  SET_IMAGE(state, url) {
    state.event.image = url;
  },
  SET_EVENT_DATES(state, dates) {
    state.eventDates = sortDates(dates);
  },
  SET_EVENT_DATE(state, date) {
    var index = state.eventDates.findIndex(obj => {
      return obj.id === date.id;
    });
    state.eventDates[index] = date;
    state.eventDates = sortDates(state.eventDates);
  },
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },
  SET_EMAIL_MESSAGE(state, message) {
    state.settings.emailMessage = message;
  },
  SET_ANALYTICS(state, analytics) {
    state.analytics = analytics;
  },
  ADD_EVENT_DATE(state, date) {
    console.log("Date add", date);
    var exists = false;
    for (var i = 0; i < state.eventDates.length; i++) {
      if (state.eventDates[i].id == null && !exists) {
        state.eventDates[i] = date;
        exists = true;
      }
    }
    if (date.id == null || !exists) state.eventDates.push(date);
    state.eventDates = sortDates(state.eventDates);
  },
  DELETE_EVENT_DATE(state, date) {
    const index = state.eventDates.indexOf(date);
    if (index > -1) {
      state.eventDates.splice(index, 1);
    }
  },
  SET_PRICE_TIERS(state, tiers) {
    state.priceTiers = tiers;
  },
  SET_PRICE_TIER(state, tier) {
    var index = state.priceTiers.findIndex(obj => {
      return obj.id === tier.id;
    });
    state.priceTiers[index] = tier;
  },
  ADD_PRICE_TIER(state, tier) {
    console.log("new tier", JSON.stringify(tier));
    var exists = false;
    for (var i = 0; i < state.priceTiers.length; i++) {
      if (state.priceTiers[i].id == null && !exists) {
        state.priceTiers[i] = tier;
        exists = true;
      }
    }
    if (tier.id == null || !exists) state.priceTiers.push(tier);
  },
  ADD_NEW_PRICE_TIER(state, tier) {
    state.newtier = tier;
  },
  CLEAR_NEW_PRICE_TIER(state) {
    state.newtier = null;
  },
  DELETE_PRICE_TIER(state, tier) {
    const index = state.priceTiers.indexOf(tier);
    if (index > -1) {
      state.priceTiers.splice(index, 1);
    }
  },
  CLEAR_VENUE(state) {
    console.log("jumped the shark");
    state.event.venue = state.event.venueId = null;
  }
};

export const actions = {
  async newEvent({ commit }, params) {
    console.log("What was passed?", params);
    //var actId = params.artist.id;
    var actname = params && params.artist ? params.artist.name : null;
    var artists = {};
    if (params && params.artist) artists[params.artist.id] = params.artist;
    else console.log("Betty White");
    const event = {
      id: null,
      actId: params && params.artist ? params.artist.id : null,
      act: actname,
      artists: artists,
      name: "",
      genre: params && params.artist ? params.artist.type : "",
      description: "",
      venue: params && params.venue ? params.venue.id : null,
      venueName: params && params.venue ? params.venue.name : null,
      start: null,
      status: "draft",
      step: 1,
      stepsCompleted: 0,
      minPrice: 0,
      maxPrice: 0,
      startDate: new Date(),
      isPublic: true
    };
    if (eventListener) eventListener = null;
    console.log("about to set the damn event", event);
    commit("SET_EVENT", event);
    commit("SET_IMAGE", "");
    commit("SET_EVENT_DATES", [blankdate]);
    commit("SET_PRICE_TIERS", [blanktier]);
    commit("SET_SETTINGS", {});
  },
  async fetchEvent({ commit }, eventId) {
    var db = firebase.firestore();
    var docRef = db.collection("events").doc(eventId);
    var doc = await docRef.get();

    if (doc.exists) {
      console.log("Found the user profile.");
      var event = doc.data();
      event.id = docRef.id;
      commit("SET_EVENT", event);

      // Set event listener
      eventListener = docRef.onSnapshot(function(snapshot) {
        let update = snapshot.data();
        console.log("event updated", update);
        commit("SET_EVENT", update);
      });

      //get settings
      var settings = await db
        .collection("events")
        .doc(eventId)
        .collection("userData")
        .doc("settings")
        .get();
      if (settings.exists) commit("SET_SETTINGS", settings.data());
      else settings = {};
    } else {
      console.log("Could not find event " + eventId);
    }
  },
  async fetchEventAnalytics({ commit }, eventId) {
    var db = firebase.firestore();
    var doc = await db
      .collection("events")
      .doc(eventId)
      .collection("userData")
      .doc("analytics")
      .get();
    if (doc.exists) {
      commit("SET_ANALYTICS", doc.data());
    } else {
      console.log("No analytics found for event " + eventId);
    }
  },
  async fetchEvents({ commit }, profileId) {
    // if (eventsListener) eventListener = null;
    var db = firebase.firestore();
    console.log("Getting events", profileId);
    var docRef = db
      .collection("events")
      .where("owners", "array-contains", profileId)
      .where("status", "!=", "deleted");
    var docs = await docRef.get();

    if (!docs.empty) {
      console.log("Found the events.");
      commit(
        "SET_EVENTS",
        docs.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find any events.");
    }
  },
  async fetchEventDates({ commit }) {
    var db = firebase.firestore();
    var docRef = db
      .collection("events")
      .doc(state.event.id)
      .collection("dates");
    var docs = await docRef.get();

    if (!docs.empty) {
      console.log("Found the event dates.", docs.docs.length);
      commit(
        "SET_EVENT_DATES",
        docs.docs.map(doc => {
          var ed = doc.data();
          if (!ed.id) ed.id = doc.id;
          return ed;
        })
      );
    } else {
      console.log("Could not find event ");
    }
  },
  async createEvent({ commit }, event) {
    var db = firebase.firestore();
    var docRef = await db
      .collection("events")
      .add(event)
      .catch(function(error) {
        console.error("Error adding eventr: ", error);
        Bugsnag.notify(error);
      });

    console.log("Document written with ID: " + JSON.stringify(docRef.id));
    await db
      .collection("events")
      .doc(docRef.id)
      .update({ id: docRef.id })
      .catch(function(error) {
        console.error("Error adding eventr: ", error);
        Bugsnag.notify(error);
      });
    var objid = { id: docRef.id, status: "draft" };
    var eventdoc = await docRef.get();
    var createdEvent = { ...objid, ...eventdoc.data() };
    createdEvent.id = docRef.id;
    commit("SET_EVENT", createdEvent);
    //this.$emit("saved", this.user);
    //var docRef = db.collection("users").doc(this.state.user.uid);
    eventListener = docRef.onSnapshot(function(snapshot) {
      let update = snapshot.data();
      console.log("event updated", update);
      commit("SET_EVENT", update);
    });
  },
  async updateEvent({ commit }, event) {
    var db = firebase.firestore();
    await db
      .collection("events")
      .doc(event.id)
      .update(event)
      .catch(function(error) {
        Bugsnag.notify(error);
        console.error("Error updating event date: ", error);
      });
    commit("SET_EVENT", event);
  },
  async createDate({ commit }, date) {
    var newdate = { ...date };
    console.log("Current event id: " + state.event.id);
    newdate.eventId = state.event.id;
    var db = firebase.firestore();
    var docRef = await db
      .collection("events")
      .doc(state.event.id)
      .collection("dates")
      .add(newdate)
      .catch(function(error) {
        Bugsnag.notify(error);
        console.error("Error adding event date: ", error);
      });

    console.log("Document written with ID: " + JSON.stringify(docRef.id));
    var eventdoc = await docRef.get();
    var eventdate = eventdoc.data();
    eventdate.id = docRef.id;
    //eventdoc.id = docRef.id;
    commit("ADD_EVENT_DATE", eventdate);
  },
  deleteEvent({ commit, dispatch }, event) {
    console.log("Deleting event: ", event);
    var db = firebase.firestore();
    db.collection("events")
      .doc(event.id)
      .update({ status: "deleted" });
    commit("EVENT_REMOVED", event);
    dispatch("fetchEventSummarys", event.userId);
  },
  async updateDate({ commit }, date) {
    var db = firebase.firestore();
    await db
      .collection("events")
      .doc(state.event.id)
      .collection("dates")
      .doc(date.id)
      .set(date)
      .catch(function(error) {
        Bugsnag.notify(error);
        console.error("Error updating event date: ", error);
      });
    commit("SET_EVENT_DATE", date);
  },
  deleteDate({ commit }, date) {
    var db = firebase.firestore();
    db.collection("events")
      .doc(state.event.id)
      .collection("dates")
      .doc(date.id)
      .delete()
      .catch(function(error) {
        Bugsnag.notify(error);
        console.error("Error deleting event date: ", error);
      });
    commit("DELETE_EVENT_DATE", date);
  },
  addNewDate({ commit }) {
    commit("ADD_EVENT_DATE", blankdate);
  },
  async fetchPriceTiers({ commit }) {
    var db = firebase.firestore();
    console.log("Fetching price tiers for event ", state.event.id);
    var docRef = db
      .collection("events")
      .doc(state.event.id)
      .collection("priceTiers");
    var tiers = await docRef.get();
    if (!tiers.empty) {
      console.log("Found the price tiers.", tiers);
      commit(
        "SET_PRICE_TIERS",
        tiers.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find any price tiers for this event.");
    }
  },
  async createTier({ commit }, tier) {
    var newtier = { ...tier };
    console.log("Current event id: " + state.event.id);
    newtier.eventId = state.event.id;
    var db = firebase.firestore();
    var docRef = await db
      .collection("events")
      .doc(state.event.id)
      .collection("priceTiers")
      .add(newtier)
      .catch(function(error) {
        Bugsnag.notify(error);
        console.error("Error adding event price tier: ", error);
      });

    console.log("Document written with ID: " + JSON.stringify(docRef.id));
    var pricedoc = await docRef.get();
    var pricetier = pricedoc.data();
    if (!pricetier.id) commit("CLEAR_NEW_PRICE_TIER");
    pricetier.id = docRef.id;
    //eventdoc.id = docRef.id;
    commit("ADD_PRICE_TIER", pricetier);
  },
  async updateTier({ commit }, tier) {
    var db = firebase.firestore();
    await db
      .collection("events")
      .doc(state.event.id)
      .collection("priceTiers")
      .doc(tier.id)
      .set(tier)
      .catch(function(error) {
        console.error("Error updating event pricing tier: ", error);
      });
    commit("SET_PRICE_TIER", tier);
  },
  addNewTier({ commit }) {
    commit("ADD_NEW_PRICE_TIER", blanktier);
  },
  deleteTier({ commit }, tier) {
    var db = firebase.firestore();
    db.collection("events")
      .doc(tier.eventId)
      .collection("priceTiers")
      .doc(tier.id)
      .delete()
      .catch(function(error) {
        Bugsnag.notify(error);
        console.error("Error deleting price tier: ", error);
      });
    commit("DELETE_PRICE_TIER", tier);
  },
  async updateEmail({ commit }, text) {
    var db = firebase.firestore();
    var settings = await db
      .collection("events")
      .doc(state.event.id)
      .collection("userData")
      .doc("settings")
      .get();

    if (settings.exist)
      settings.update({ emailMessage: text }).catch(function(error) {
        console.error("Error deleting price tier: ", error);
      });
    else
      db.collection("events")
        .doc(state.event.id)
        .collection("userData")
        .doc("settings")
        .set({ emailMessage: text });

    commit("SET_EMAIL_MESSAGE", text);
  },
  async updateEventSettings({ commit }, settingsData) {
    var db = firebase.firestore();
    var settings = await db
      .collection("events")
      .doc(state.event.id)
      .collection("userData")
      .doc("settings")
      .get();

    if (settings.exist)
      settings.update({ ...settingsData }).catch(function(error) {
        console.error("Error deleting price tier: ", error);
      });
    else
      db.collection("events")
        .doc(state.event.id)
        .collection("userData")
        .doc("settings")
        .set({ ...settingsData });

    commit("SET_SETTINGS", settingsData);
  },
  async duplicateEvent({ commit }, eventId) {
    // Get the originating event
    var db = firebase.firestore();
    var oldEventRef = await db.collection("events").doc(eventId);
    var oldEventData = await (await oldEventRef.get()).data();
    // null out fields that must change
    oldEventData.capacity = null;
    oldEventData.start = null;
    oldEventData.endTime = null;
    oldEventData.stepsCompleted = 0;
    oldEventData.copied = true;
    oldEventData.venue = null;
    oldEventData.venueName = null;
    oldEventData.status = "draft";
    oldEventData.title = "copy of " + oldEventData.title;
    // create new event
    var newEventRef = await db.collection("events").add(oldEventData);
    // copy settings
    var oldSettings = await (
      await oldEventRef
        .collection("userData")
        .doc("settings")
        .get()
    ).data();
    await newEventRef
      .collection("userData")
      .doc("settings")
      .set(oldSettings);
    // add price tiers
    var oldPriceTiers = await oldEventRef.collection("priceTiers").get();
    console.log("oldprices", oldPriceTiers.docs);
    if (!oldPriceTiers.empty) {
      for (const oldTier of oldPriceTiers.docs) {
        var newTier = oldTier.data();
        newTier.selectDates = false;
        newTier.selectedDates = [];
        newTier.eventId = newEventRef.id;
        newEventRef.collection("priceTiers").add(newTier);
      }
    }
    commit("SET_EVENT", (await newEventRef.get()).data());
    return newEventRef.id;
  },
  clearVenue({ commit }) {
    commit("CLEAR VENUE");
  }
};

export const getters = {
  event(state) {
    return state.event;
  },
  events(state) {
    return state.events;
  },
  eventDates(state) {
    return state.eventDates;
  },
  priceTiers(state) {
    return state.priceTiers;
  },
  newtier(state) {
    return state.newtier;
  },
  settings(state) {
    return state.settings;
  },
  analytics(state) {
    return state.analytics;
  },
  eventDate(state) {
    if (state.eventDates.length == 0) return "";
    else if (state.eventDates.length == 1)
      return moment(state.eventDates[0].startDate).format("dddd, MMM Do");
    else
      return (
        moment(state.eventDates[0].startDate).format("MMM Do") +
        " - " +
        moment(state.eventDates[state.eventDates.length - 1].startDate).format(
          "MMM Do"
        )
      );
  },
  eventTime(state) {
    if (state.eventDates.length == 1)
      return moment(state.eventDates[0]).format("hA");
    else return "";
  },
  eventPrice() {
    if (state.event.minPrice == state.event.maxPrice)
      return "$" + state.event.minPrice;
    else return "$" + state.event.minPrice + "-" + state.event.maxPrice;
  },
  eventEndMonth() {
    return moment(
      state.eventDates[state.eventDates.length - 1].startDate
    ).format("MMM");
  },
  eventEndDay() {
    return moment(
      state.eventDates[state.eventDates.length - 1].startDate
    ).format("D");
  }
};

function sortDates(dates) {
  dates.sort(function(a, b) {
    return (
      moment(a.startDate ? a.startDate : "2050-01-01" + " " + a.startTime, "YYYY-MM-DD hh:mm A").toDate() -
      moment(b.startDate ? b.startDate : "2050-01-01" + " " + b.startTime, "YYYY-MM-DD hh:mm A").toDate()
    );
  });
  console.log("dates sorted", dates);
  return dates;
}
