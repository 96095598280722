export const state = {
  confirmation: null,
  showConfirm: false,
  confirmAction: "",
  confirmObject: null
};

export const mutations = {
  SET_CONFIRMATION(state, confirmation) {
    if (confirmation) {
      state.showConfirm = true;
      state.confirmation = confirmation;
      state.confirmAction = confirmation.action;
      state.confirmObject = confirmation.object;
    }
  },
  CLEAR_CONFIRMATION(state) {
    state.showConfirm = false;
    state.confirmPromise = null;
    state.confirmAction = "";
    state.confirmObject = null;
  }
};

export const actions = {
  showConfirmation({ commit }, confirmation) {
    console.log("confirming");
    if (!state.confirmAction) {
      commit("SET_CONFIRMATION", confirmation);
    }
  },
  confirm({ commit, dispatch }, response) {
    console.log(state.confirmAction, state.confirmObject);
    if (state.confirmAction && response)
      dispatch(state.confirmAction, state.confirmObject);
    commit("CLEAR_CONFIRMATION");
  }
};

export const getters = {
  showConfirm(state) {
    return state.showConfirm;
  },
  confirmation(state) {
    return state.confirmation;
  }
};
