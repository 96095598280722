var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("img", {
      attrs: { id: "logo", src: "/img/gobo-black-square.png", alt: "Gobo Logo" }
    }),
    _vm.invitationError
      ? _c("div", [
          _c("h3", [_vm._v("Invitation Not Found")]),
          _c("p", [
            _vm._v(
              " We're sorry, we can't find that invitation in our system. Our team has been notified that something went wrong. Please reach out to the person who sent you the invite. "
            )
          ])
        ])
      : _vm._e(),
    !_vm.invitationError
      ? _c("div", [
          _c("h3", [
            _vm._v(
              _vm._s(
                _vm.receivedInvite
                  ? "Accept your invitation"
                  : "Sign Up for Gobo"
              )
            )
          ]),
          _vm.errorMessage
            ? _c(
                "div",
                {
                  staticClass: "notification is-danger is-light",
                  class: {
                    "m-fadeIn": _vm.errorMessage,
                    "m-fadeOut": !_vm.errorMessage
                  }
                },
                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
              )
            : _vm._e(),
          _c("p", { staticClass: "overview" }, [
            _vm._v(" " + _vm._s(_vm.messages[_vm.step - 1]) + " ")
          ]),
          _vm._m(0)
        ])
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step == 2,
            expression: "step == 2"
          }
        ]
      },
      [
        _c("p", [
          _vm._v(
            "We've created your account! Get started selling tickets today."
          )
        ])
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step == 3,
            expression: "step == 3"
          }
        ],
        staticClass: "container"
      },
      [
        _vm._m(1),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column is-one-third is-align-items-end" }, [
            _c("div", { staticClass: "buttons is-right" }, [
              _c(
                "a",
                {
                  class:
                    _vm.user.type == "artist" ? "button is-primary" : "button",
                  on: {
                    click: function($event) {
                      _vm.user.type = "artist"
                    }
                  }
                },
                [_vm._v("Artist or Act")]
              )
            ])
          ]),
          _c("div", { staticClass: "column is-two-thirds has-text-left" }, [
            _vm._v(
              " Are you or do you represent a musician, a band, a theater ensemble, a podcaster, a storteller, a comedian, or anyone else who performs live? Choose this option. "
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column is-one-third" }, [
            _c("div", { staticClass: "buttons is-right" }, [
              _c(
                "a",
                {
                  class:
                    _vm.user.type == "venue" ? "button is-primary" : "button",
                  on: {
                    click: function($event) {
                      _vm.user.type = "venue"
                    }
                  }
                },
                [_vm._v("Venue")]
              )
            ])
          ]),
          _c("div", { staticClass: "column is-two-thirds has-text-left" }, [
            _vm._v(
              " Do you represent a physical location that hosts artists or acts throughout the year? Choose this option. "
            )
          ])
        ]),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column is-one-third" }, [
            _c("div", { staticClass: "buttons is-right" }, [
              _c(
                "a",
                {
                  class:
                    _vm.user.type == "festival"
                      ? "button is-primary"
                      : "button",
                  on: {
                    click: function($event) {
                      _vm.user.type = "festival"
                    }
                  }
                },
                [_vm._v("Festival or Event")]
              )
            ])
          ]),
          _c("div", { staticClass: "column is-two-thirds has-text-left" }, [
            _vm._v(
              " Do you represent a one time or periodically recurring event that hosts artists? Choose this option. "
            )
          ])
        ])
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step == 2 && _vm.user.type == "artist",
            expression: "step == 2 && user.type == 'artist'"
          }
        ],
        staticClass: "container"
      },
      [
        _vm._m(2),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c("div", { staticClass: "buttons is-right" }, [
              _c(
                "a",
                {
                  class:
                    _vm.user.type == "artist" ? "button is-primary" : "button",
                  on: {
                    click: function($event) {
                      _vm.user.type = "artist"
                    }
                  }
                },
                [_vm._v("Solo Artist")]
              ),
              _c(
                "a",
                {
                  class:
                    _vm.user.type == "artist" ? "button is-primary" : "button",
                  on: {
                    click: function($event) {
                      _vm.user.type = "artist"
                    }
                  }
                },
                [_vm._v("Band")]
              )
            ])
          ])
        ])
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.invitationError && _vm.step == 1,
            expression: "!invitationError && step == 1"
          }
        ]
      },
      [
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Name:")]),
          _c("div", { staticClass: "control has-icons-left" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.name,
                  expression: "user.name"
                }
              ],
              staticClass: "input is-primary",
              attrs: { type: "text", placeholder: "Name", required: "" },
              domProps: { value: _vm.user.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "name", $event.target.value)
                }
              }
            }),
            _vm._m(3)
          ]),
          _vm.nameChanged && !_vm.user.name
            ? _c("p", { staticClass: "help is-danger" }, [
                _vm._v(" Please enter a name. ")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "field is-clearfix" }, [
          _c("label", { staticClass: "label" }, [_vm._v("My email is:")]),
          _c("div", { staticClass: "control has-icons-left" }, [
            !_vm.receivedInvite
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.email,
                      expression: "user.email"
                    }
                  ],
                  staticClass: "input is-primary",
                  attrs: { type: "email", required: "", placeholder: "Email" },
                  domProps: { value: _vm.user.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.user, "email", $event.target.value)
                    }
                  }
                })
              : _vm._e(),
            _vm.receivedInvite
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.receivedInvite.email,
                      expression: "receivedInvite.email"
                    }
                  ],
                  staticClass: "input is-primary",
                  attrs: {
                    type: "email",
                    required: "",
                    placeholder: "Email",
                    disabled: "true"
                  },
                  domProps: { value: _vm.receivedInvite.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.receivedInvite, "email", $event.target.value)
                    }
                  }
                })
              : _vm._e(),
            _vm._m(4)
          ]),
          !_vm.emailValid
            ? _c("p", { staticClass: "help is-danger" }, [
                _vm._v(" Please enter a valid email address. ")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Password:")]),
          _c("div", { staticClass: "control" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.password,
                  expression: "user.password"
                }
              ],
              staticClass: "input is-primary",
              attrs: {
                type: "password",
                placeholder: "Password",
                required: ""
              },
              domProps: { value: _vm.user.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "password", $event.target.value)
                }
              }
            })
          ])
        ]),
        _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "control" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.confirmPassword,
                  expression: "user.confirmPassword"
                }
              ],
              staticClass: "input is-primary",
              attrs: {
                type: "password",
                placeholder: "Confirm Password",
                required: ""
              },
              domProps: { value: _vm.user.confirmPassword },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "confirmPassword", $event.target.value)
                }
              }
            })
          ]),
          !_vm.passwordsMatch
            ? _c("p", { staticClass: "help is-danger" }, [
                _vm._v(" Passwords do not match. ")
              ])
            : _vm._e()
        ])
      ]
    ),
    !_vm.invitationError
      ? _c("div", { staticClass: "container cta" }, [
          _c("div", { staticClass: "buttons is-centered" }, [
            _c(
              "button",
              {
                staticClass: "button is-primary is-fullwidth",
                attrs: { disabled: !_vm.isValid },
                on: { click: _vm.createuser }
              },
              [_vm._v(" Create Account ")]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step == 3,
                    expression: "step == 3"
                  }
                ],
                staticClass: "button",
                attrs: { disabled: !_vm.isValid },
                on: { click: _vm.createuser }
              },
              [_vm._v(" Sign Up ")]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "disclaimer" }, [
      _vm._v(" By signing up you agree to our "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.gobo.show/terms-of-service"
          }
        },
        [_vm._v("terms of service")]
      ),
      _vm._v(
        ". We charge a 5% fee that includes credit card processing fees. For more information, "
      ),
      _c(
        "a",
        { attrs: { target: "_blank", href: "https://www.gobo.show/why-gobo" } },
        [_vm._v("click here")]
      ),
      _vm._v(". ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column is-one-third" }, [
        _c("label", { staticClass: "label has-text-right" }, [
          _vm._v(" I am or represent a(n):")
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column is-one-third" }, [
        _c("label", { staticClass: "label has-text-right" }, [
          _vm._v("What type of artist?")
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-user" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-envelope" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }