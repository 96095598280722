<template>
  <div class="price-tiers" v-if="selectedDate">
    <div v-for="tier in tiers" :key="tier.id">
      <div class="columns">
        <div class="column">
          <h4>{{ tier.name }}</h4>
          <p class="tier-description" v-if="tier.description">
            {{ tier.description }}
          </p>
          <p v-if="tier.feeType == 'fixed'">${{ tier.price }}</p>
          <p v-if="tier.feeType == 'free'">Free!</p>
          <div v-if="tier.feeType == 'sliding'">
            <div>
              <span
                ><strong>${{ tier.price }} per ticket</strong></span
              >
            </div>
            <div>
              <input
                class="slider is-fullwidth is-success"
                step="1"
                :min="tier.minPrice"
                :max="tier.maxPrice"
                v-model.number="tier.price"
                type="range"
              />
            </div>
            <div class="level">
              <div class="level-left">${{ tier.minPrice }}</div>
              <div class="level-right">${{ tier.maxPrice }}</div>
            </div>
          </div>
        </div>
        <div class="column is-narrow">
          <div class="select is-dark">
            <select v-model.number="tier.quantity">
              <option value="0">0</option>
              <option value="1" v-if="maxSale >= 1">1</option>
              <option value="2" v-if="maxSale >= 2">2</option>
              <option value="3" v-if="maxSale >= 3">3</option>
              <option value="4" v-if="maxSale >= 4">4</option>
              <option value="5" v-if="maxSale >= 5">5</option>
              <option value="6" v-if="maxSale >= 6">6</option>
              <option value="7" v-if="maxSale >= 7">7</option>
              <option value="8" v-if="!maxSale >= 8">8</option>
              <option value="9" v-if="maxSale >= 9">9</option>
              <option value="10" v-if="maxSale >= 10">10</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tiers: Array,
    selectedDate: Boolean,
    maxSale: Number,
    visible: Boolean
  },
  data: function() {
    return {
      width: 0,
      initializing: true,
      currentOffset: 0,
      sliderIndex: 0
    };
  },
  methods: {
    setIndexOffset() {
      let offset = 0;
      for (let x = 0; x > this.sliderIndex; x--) {
        console.log("x is ", x);
        offset -= this.$refs.sliderItem[x * -1].clientWidth - 20;
      }
      this.currentOffset = offset;
    }
  },
  watch: {
    visible: function(val, oldVal) {
      console.log("new: %s, old: %s", val, oldVal);
      this.initializing = true;
      if (this.$refs.wrapper) this.width = this.$refs.wrapper.clientWidth;
      else console.log("wrapper not found");
      console.log("width at watch", this.width);
      this.initializing = false;
    }
  },
  computed: {
    showLeftSlider: function() {
      return true;
      //return this.sliderIndex > 0;
    },
    showRightSlider: function() {
      if (this.dates && this.$refs.slider)
        console.log(
          "current",
          this.$refs.slider.clientWidth + this.currentOffset
        );
      if (
        this.dates &&
        this.sliderIndex &&
        this.$refs.slider &&
        this.$refs.viewport
      )
        return (
          this.$refs.slider.clientWidth + this.currentOffset <
          this.$refs.viewport.clientWidth
        );
      else return false;
    },
    viewportwidth: function() {
      return this.width - 48 - 48;
    },
    filtereddates: function() {
      return this.dates.filter(
        date =>
          date.status != "soldout" &&
          date.start.toDate() > Date.now() &&
          date.status != "cancelled"
      );
    }
  },
  mounted: function() {
    if (this.$refs.wrapper && this.$refs.wrapper != this.width) {
      this.initializing = true;
      this.width = this.$refs.wrapper.clientWidth;
      console.log("Slider updated!");
      this.initializing = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
</style>
