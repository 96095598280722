<template>
  <div>
    <img id="logo" src="/img/gobo-black-square.png" alt="Gobo Logo" />
    <h3>Sign Out</h3>
    <p>Are you sure you want to sign out?</p>
    <div class="buttons is-centered">
      <button class="button is-fullwidth" @click="cancel">Cancel</button>
      <button class="button is-fullwidth is-primary" @click="logout">Sign Out</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "LogOut",
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    logout() {
      console.log("Logging out");
      firebase
        .auth()
        .signOut()
        .then(
          function() {
            // Sign-out successful.
          },
          function(error) {
            // Handle Errors here.
            //var errorCode = error.code;
            //var errorMessage = error.message;
            console.log("Logout error " + JSON.stringify(error));
          }
        );
      this.$emit("loggedout");
    }
  }
};
</script>
