/* eslint-disable no-unused-vars */
import firebase from "firebase/app";

export const state = {
  orders: [],
};

export const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders;
  }
};

export const actions = {
  async getOrders({ commit }, eventId) {
    var db = firebase.firestore();
    var orderRef = db
      .collection("events")
      .doc(eventId)
      .collection("userData")
      .doc("salesReport")
      .collection("orders")
      .orderBy("created", "desc");
    var docs = await orderRef.get();

    if (!docs.empty) {
        commit(
          "SET_ORDERS",
          docs.docs.map(doc => doc.data())
        );
      } else {
        console.log("Could not find any orders for this event.");
      }
  },
};

export const getters = {
  orders(state) {
    return state.orders;
  },
  };
