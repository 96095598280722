<template>
  <div>
    <img id="logo" src="/img/gobo-black-square.png" alt="Gobo Logo" />
    <div v-show="mode == 'login'">
      <h3>Sign In</h3>
      <p>Unlock the power of Gobo.</p>
      <div
        class="notification is-danger is-light"
        v-bind:class="{ 'm-fadeIn': errorMessage, 'm-fadeOut': !errorMessage }"
      >
        {{ errorMessage }}
      </div>
      <div class="field">
        <label class="label">Email</label>
        <div class="control has-icons-left">
          <input
            class="input is-primary"
            type="text"
            placeholder="Email"
            v-model="user.email"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
        </div>
        <p class="help is-danger" v-if="!emailValid">
          Please enter a valid email address.
        </p>
      </div>
      <div class="field">
        <label class="label">Password</label>
        <div class="control has-icons-left">
          <input
            class="input is-primary"
            type="password"
            placeholder="Password"
            v-model="user.password"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-key"></i>
          </span>
        </div>
        <div id="forgot">
          <a class="primary" @click="mode = 'forgot'">Forgot your password?</a>
        </div>
      </div>
      <div class="buttons is-centered">
        <button
          class="button is-primary is-fullwidth"
          @click="login"
          :disabled="!isValid"
        >
          Sign In
        </button>
      </div>
    </div>
    <div v-show="mode == 'forgot'">
      <h3>Forgot your password?</h3>
      <p>
        Forgot your password? No BD. Enter your email address and we'll send you
        a link to reset it.
      </p>
      <div class="field is-clearfix">
        <label class="label">My email is:</label>
        <div class="control has-icons-left">
          <input
            class="input is-primary"
            type="email"
            required
            placeholder="Email"
            v-model="user.email"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
        </div>
        <p class="help is-danger" v-if="!emailValid">
          Please enter a valid email address.
        </p>
      </div>
      <div class="buttons is-centered">
        <button
          class="button is-primary  is-fullwidth"
          @click="sendReset"
          :disabled="!isValid"
        >
          Send Reset Email
        </button>
      </div>
    </div>
    <div v-show="mode == 'sent'">
      <h3>Email Sent</h3>
      <p>
        We have sent you an email you can use to reset your password. Your email
        should arrive within five minutes.
      </p>
      <div class="buttons is-centered">
        <button class="button is-primary is-fullwidth" @click="mode = 'login'">
          Back to Login
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
#forgot {
  padding: 1em 0;
}
.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  transition: visibility 0s linear 600ms, opacity 600ms, height 600ms,
    padding-top 600ms;
}
.m-fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 600ms, height 600ms,
    padding-top 600ms, padding-bottom 600ms;
}
</style>

<script>
import firebase from "firebase/app";

export default {
  name: "LogIn",
  data() {
    return {
      mode: "login",
      emailValid: true,
      errorMessage: "",
      user: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    login() {
      console.log("Authenticating");
      firebase
        .auth()
        .signInWithEmailAndPassword(this.user.email, this.user.password)
        .then(data => {
          //await store.dispatch("fetchProfiles");
          console.log("Data? ", data.uid);
          this.$emit("loggedin", data.uid);
        })
        .catch(error => {
          // Handle Errors here.
          var errorCode = error.code;
          this.errorMessage = error.message;
          console.log(
            "Authentication error " + errorCode + ": " //+ errorMessage
          );
        });
      //
    },
    validEmail() {
      //var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //return re.test(email);
      return true;
    },
    async sendReset() {
      await firebase
        .auth()
        .sendPasswordResetEmail(this.user.email)
        .then(function() {
          // Password reset confirmation sent. Ask user to check their email.
        })
        .catch(function(error) {
          // Error encountered while sending password reset code.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(
            "Authentication error " + errorCode + ": " + errorMessage
          );
        });
      this.mode = "sent";
    }
  },
  computed: {
    isValid() {
      if (this.errorMessage) return false;
      if (this.mode == "forgot") return this.emailValid;
      else {
        return this.user.password && this.emailValid && this.user.email;
      }
    }
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.email) this.emailValid = this.validEmail(newValue.email);
        else this.emailValid = true;
        if (this.errorMessage) this.errorMessage = "";
      }
    }
  }
};
</script>
