<template>
  <div>
    <div v-if="status == 'adding'">
      <h1>Add Pass(es)</h1>
      <div class="field is-clearfix">
        <label class="label">First Name:</label>
        <div class="control">
          <input
            class="input is-primary"
            type="text"
            required
            placeholder="First Name"
            v-model="firstName"
          />
        </div>
      </div>
      <div class="field is-clearfix">
        <label class="label">Last Name:</label>
        <div class="control">
          <input
            class="input is-primary"
            type="text"
            required
            placeholder="Last Name"
            v-model="lastName"
          />
        </div>
      </div>
      <div class="field is-clearfix">
        <label class="label">Email:</label>
        <EmailField
          :email.sync="email"
          :valid.sync="emailValid"
          placeholder="Email Address"
        />
      </div>
      <div v-if="false" class="field is-clearfix">
        <input
          type="checkbox"
          v-model="addWillcall"
          id="showPreshowReminder"
          class="is-checkradio is-success"
        />
        <label for="showPreshowReminder">
          Apply to current performance
        </label>
      </div>
      <div v-if="false || addWillcall" class="quantityPicker">
        <label class="label">Number of passes:</label>
        <input
          type="radio"
          id="quantityone"
          class="is-checkradio"
          name="quantity"
          v-model.number="quantity"
          value="1"
        />
        <label for="quantityone">1</label>
        <input
          type="radio"
          id="quantitytwo"
          class="is-checkradio"
          name="quantity"
          v-model.number="quantity"
          value="2"
        />
        <label for="quantitytwo">2</label>
        <input
          type="radio"
          id="quantitythree"
          class="is-checkradio"
          name="quantity"
          v-model.number="quantity"
          value="3"
        />
        <label for="quantitythree">3</label>
        <input
          type="radio"
          id="quantityfour"
          class="is-checkradio"
          v-model.number="quantity"
          name="quantity"
          value="4"
        />
        <label for="quantityfour">4</label>
        <input
          type="radio"
          id="quantityfive"
          class="is-checkradio"
          v-model.number="quantity"
          name="quantity"
          value="255"
        />
        <label for="quantityfive">5</label>
      </div>
      <div class="columns buttons">
        <div class="column">
          <button
            :disabled="valid"
            class="button is-fullwidth is-primary"
            @click="clear()"
          >
            Clear
          </button>
        </div>
        <div class="column">
          <button
            :disabled="valid"
            class="button is-fullwidth is-primary"
            @click="add('Credit')"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
    <div v-if="status == 'added'">
      <h3>Pass created and sent to {{ email }}!</h3>
      <p>The pass may now be used at walk up or to purchase advance tickets online.</p>
      <div class="figure">
        <img src="/img/illustration-email.png" alt="email sent" />
      </div>
      <div class="buttons is-centered">
        <button class="button" @click="clear()">Add another pass</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import firebase from "firebase/app";
import EmailField from "@/components/formControls/email-field";

export default {
  name: "AddPass",
  data() {
    return {
      quantity: 1,
      addWillcall: true,
      firstName: "",
      lastName: "",
      email: "",
      emailValid: false,
      status: "adding"
    };
  },
  components: { EmailField },
  computed: {
    ...mapGetters({
      priceTiers: "priceTiers",
      dateSummary: "dateSummary",
      passes: "passes",
      event: "event"
    }),
    valid: function() {
      return this.firstName && this.lastName && !this.emailValid;
    }
  },
  async created() {
    console.log("Get price tiers.");
    await store.dispatch("fetchPriceTiers");
  },
  methods: {
    getPassCounts(pass) {
      if (pass.passType == "unlimited") return "unlimited";
      const used = pass.usedCount ? pass.usedCount : "0";
      return used + " / " + pass.limit;
    },
    clear() {
      this.quantity = 1;
      this.firstName = this.lastName = this.email = "";
      this.status = "adding";
    },
    setPass(pass) {
      this.selectedPass = pass;
      this.quantity = 1;
      if (pass.passType == "unlimited") this.maxQuantity = 1;
      else
        this.maxQuantity = pass.usedCount
          ? pass.limit - pass.usedCount
          : pass.limit;
    },
    async add() {
      var db = firebase.firestore();
      const order = {
        email: this.email,
        festivalId: this.event.festivalId,
        firstName: this.firstName,
        lastName: this.lastName,
        optin: false,
        paidBy: "Square",
        seasonId: this.event.seasonId,
        status: "submittedByFoh",
        submittedBy: "FOHWeb",
        subtotal: 70 * this.quantity,
        total: 70 * this.quantity
      };
      console.log("order", order);
      const orderItem = {
        description: "Five Frieghts Pass",
        festivalId: this.event.festivalId,
        festivalTitle: "Twin Cities Horror Festival XII",
        passId: "pEJjyU53YkHZY2jMgu6x",
        price: 70 * this.quantity,
        quantity: this.quantity,
        seasonId: this.event.seasonId,
        total: 70 * this.quantity
      };
      console.log("line item", orderItem);
      var orderRef = await db.collection("orders").add(order);
      await db
        .collection("orders")
        .doc(orderRef.id)
        .collection("lineItems")
        .add(orderItem);
      orderRef.update({ status: "paymentComplete" });
      this.status = "added";
      //   let price = this.selectedPass.passType == "unlimited" ? 0 : 14;
      //   const lineItem = {
      //     checkedin: this.quantity,
      //     firstName: this.selectedPass.firstName,
      //     lastName: this.selectedPass.lastName,
      //     email: this.selectedPass.email,
      //     passId: this.selectedPass.passId,
      //     passInstanceId: this.selectedPass.id,
      //     tierName: this.selectedPass.passname,
      //     quantity: 1,
      //     price: price,
      //     total: price * this.quantity,
      //     status: "checkedin"
      //   };
      //   db.collection("events")
      //     .doc(this.event.id)
      //     .collection("userData")
      //     .doc("audienceReport")
      //     .collection("dates")
      //     .doc(this.dateSummary.dateId)
      //     .collection("audience")
      //     .add(lineItem)
      //     .catch(function(error) {
      //       console.error("Error adding walkup: ", error);
      //     });
      //   this.quantity = 1;
      //   this.passSearch = "";
      //   this.selectedPass = null;
      //   this.$emit("passApplied");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.results {
  margin-top: 30px;
}
.selectable:hover {
  background-color: $gray6;
  cursor: pointer;
}
.passname {
  font-size: 14px;
  line-height: 4px;
}
.count {
  margin-right: 10px;
}
.quantityPicker {
  margin: 20px 0 30px;
}
</style>
