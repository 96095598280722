var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box" }, [
    _c("h3", [_vm._v("Artist Profile")]),
    _c("p", { staticClass: "overview" }, [
      _vm._v(
        " You're almost there! Tell us a little about you and we'll get you started booking gigs and selling tickets. If you represent multiple acts, don't worry, you'll have a chance to add those later. "
      )
    ]),
    _c("div", { staticClass: "field is-pulled-left" }, [
      _c("label", { staticClass: "label has-text-left" }, [
        _vm._v("Artist type")
      ]),
      _c("div", { staticClass: "select" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.artist.type,
                expression: "artist.type"
              }
            ],
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.artist,
                  "type",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", [_vm._v("Theater")]),
            _c("option", [_vm._v("Improv")]),
            _c("option", [_vm._v("Storyteller/Spoken Word Artist")]),
            _c("option", [_vm._v("Podcaster")]),
            _c("option", [_vm._v("Musician")]),
            _c("option", { attrs: { value: "" } }, [_vm._v("Music Theater")]),
            _c("option", [_vm._v("Band")]),
            _c("option", [_vm._v("Choir")]),
            _c("option", [_vm._v("Comedian")]),
            _c("option", [_vm._v("Magician")]),
            _c("option", [_vm._v("Other")])
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label has-text-left" }, [
        _vm._v("Group or Ensemble Name (if applicable)")
      ]),
      _c("div", { staticClass: "control" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.artist.name,
              expression: "artist.name"
            }
          ],
          staticClass: "input is-primary",
          attrs: { type: "text", placeholder: "Name" },
          domProps: { value: _vm.artist.name },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.artist, "name", $event.target.value)
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "field is-clearfix" }, [
      _c("label", { staticClass: "label has-text-left is-clearfix" }, [
        _vm._v("Location")
      ]),
      _c("div", { staticClass: "control" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.artist.location,
              expression: "artist.location"
            }
          ],
          staticClass: "input is-primary",
          attrs: { type: "text", placeholder: "Location" },
          domProps: { value: _vm.artist.location },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.artist, "location", $event.target.value)
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "field is-clearfix" }, [
      _c("label", { staticClass: "label has-text-left is-clearfix" }, [
        _vm._v("Description")
      ]),
      _c("div", { staticClass: "control" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.artist.description,
              expression: "artist.description"
            }
          ],
          staticClass: "input is-primary",
          attrs: { type: "textarea", placeholder: "Tell us about yourself." },
          domProps: { value: _vm.artist.description },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.artist, "description", $event.target.value)
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "buttons is-centered" }, [
      _c("button", { staticClass: "button", on: { click: _vm.cancel } }, [
        _vm._v("Cancel")
      ]),
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { disabled: !_vm.isValid },
          on: { click: _vm.createuser }
        },
        [_vm._v(" Sign Up ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }