var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("img", {
      attrs: { id: "logo", src: "/img/gobo-black-square.png", alt: "Gobo Logo" }
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.mode == "login",
            expression: "mode == 'login'"
          }
        ]
      },
      [
        _c("h3", [_vm._v("Sign In")]),
        _c("p", [_vm._v("Unlock the power of Gobo.")]),
        _c(
          "div",
          {
            staticClass: "notification is-danger is-light",
            class: {
              "m-fadeIn": _vm.errorMessage,
              "m-fadeOut": !_vm.errorMessage
            }
          },
          [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
        ),
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Email")]),
          _c("div", { staticClass: "control has-icons-left" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.email,
                  expression: "user.email"
                }
              ],
              staticClass: "input is-primary",
              attrs: { type: "text", placeholder: "Email" },
              domProps: { value: _vm.user.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "email", $event.target.value)
                }
              }
            }),
            _vm._m(0)
          ]),
          !_vm.emailValid
            ? _c("p", { staticClass: "help is-danger" }, [
                _vm._v(" Please enter a valid email address. ")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Password")]),
          _c("div", { staticClass: "control has-icons-left" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.password,
                  expression: "user.password"
                }
              ],
              staticClass: "input is-primary",
              attrs: { type: "password", placeholder: "Password" },
              domProps: { value: _vm.user.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "password", $event.target.value)
                }
              }
            }),
            _vm._m(1)
          ]),
          _c("div", { attrs: { id: "forgot" } }, [
            _c(
              "a",
              {
                staticClass: "primary",
                on: {
                  click: function($event) {
                    _vm.mode = "forgot"
                  }
                }
              },
              [_vm._v("Forgot your password?")]
            )
          ])
        ]),
        _c("div", { staticClass: "buttons is-centered" }, [
          _c(
            "button",
            {
              staticClass: "button is-primary is-fullwidth",
              attrs: { disabled: !_vm.isValid },
              on: { click: _vm.login }
            },
            [_vm._v(" Sign In ")]
          )
        ])
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.mode == "forgot",
            expression: "mode == 'forgot'"
          }
        ]
      },
      [
        _c("h3", [_vm._v("Forgot your password?")]),
        _c("p", [
          _vm._v(
            " Forgot your password? No BD. Enter your email address and we'll send you a link to reset it. "
          )
        ]),
        _c("div", { staticClass: "field is-clearfix" }, [
          _c("label", { staticClass: "label" }, [_vm._v("My email is:")]),
          _c("div", { staticClass: "control has-icons-left" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.email,
                  expression: "user.email"
                }
              ],
              staticClass: "input is-primary",
              attrs: { type: "email", required: "", placeholder: "Email" },
              domProps: { value: _vm.user.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "email", $event.target.value)
                }
              }
            }),
            _vm._m(2)
          ]),
          !_vm.emailValid
            ? _c("p", { staticClass: "help is-danger" }, [
                _vm._v(" Please enter a valid email address. ")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "buttons is-centered" }, [
          _c(
            "button",
            {
              staticClass: "button is-primary is-fullwidth",
              attrs: { disabled: !_vm.isValid },
              on: { click: _vm.sendReset }
            },
            [_vm._v(" Send Reset Email ")]
          )
        ])
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.mode == "sent",
            expression: "mode == 'sent'"
          }
        ]
      },
      [
        _c("h3", [_vm._v("Email Sent")]),
        _c("p", [
          _vm._v(
            " We have sent you an email you can use to reset your password. Your email should arrive within five minutes. "
          )
        ]),
        _c("div", { staticClass: "buttons is-centered" }, [
          _c(
            "button",
            {
              staticClass: "button is-primary is-fullwidth",
              on: {
                click: function($event) {
                  _vm.mode = "login"
                }
              }
            },
            [_vm._v(" Back to Login ")]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-envelope" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-key" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-envelope" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }