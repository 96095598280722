<template>
  <div class="avatar-generic" v-if="user">
    <img :src="user.avatar" alt="" v-if="user.avatar" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Avatar",
  //data() {},
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  }
};
</script>
