var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Add Walkup")]),
      _c("Tierpicker", {
        attrs: {
          tiers: _vm.priceTiers,
          selectedDate: true,
          maxSale: _vm.dateSummary.inventory
        }
      }),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column" }, [
          _c(
            "button",
            {
              staticClass: "button is-fullwidth is-primary",
              on: {
                click: function($event) {
                  return _vm.add("Cash")
                }
              }
            },
            [_vm._v(" Cash ")]
          )
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "button",
            {
              staticClass: "button is-fullwidth is-primary",
              on: {
                click: function($event) {
                  return _vm.add("Credit")
                }
              }
            },
            [_vm._v(" Credit ")]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }