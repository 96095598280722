import firebase from "firebase/app";
//import "firebase/firestore";

export const state = {
  invite: null,
  receivedInvite: null
};

// eslint-disable-next-line no-unused-vars
var inviteListener = null;

export const mutations = {
  SET_INVITE(state, invite) {
    state.invite = invite;
  },
  SET_RECEIVED_INVITE(state, invite) {
    state.receivedInvite = invite;
  },
  CLEAR_INVITE(state) {
    state.invite = null;
  }
};

export const actions = {
  newInvite({ commit }, invite) {
    console.log("NEW INVITE YEAAH");
    if (invite.actId)
      invite.message =
        invite.userName +
        " would like to invite you to manage the artist profile for " +
        invite.profileName +
        " on Gobo. This will help them sell tickets to your shows.\n\nGobo is a live event ticket sales platform designed to make selling tickets easy, transparent, and affordable.";
    else if (invite.venueId && invite.status == "existing")
      invite.message =
        invite.userName +
        " would like to invite you to manage the venue profile for " +
        invite.profileName +
        " on Gobo. This will help them sell tickets to shows at the venue.\n\nGobo is a live event ticket sales platform designed to make selling tickets easy, transparent, and affordable.";
    else if (invite.festivalId)
      invite.message =
        invite.userName +
        " would like to invite you to manage the festival profile for " +
        invite.profileName +
        " on Gobo. This will help them sell tickets to shows for the festival.\n\nGobo is a live event ticket sales platform designed to make selling tickets easy, transparent, and affordable.";
    else
      invite.message =
        invite.userName +
        " is doing a show at " +
        invite.profileName +
        " and would like to invite you to manage the " +
        invite.profileName +
        " profile on Gobo, their ticketing platform. This will help them sell tickets to your shows.\n\nGobo is a live event ticket sales platform designed to make selling tickets easy, transparent, and affordable.";
    commit("SET_INVITE", invite);
  },
  clearInvite({ commit }) {
    commit("CLEAR_INVITE");
  },
  async submitInvite({ commit }, invite) {
    var db = firebase.firestore();
    invite.status = "submitted";
    var inviteRef = await db.collection("invitations").add(invite);
    inviteListener = inviteRef.onSnapshot(function(snapshot) {
      let update = snapshot.data();
      console.log("invite updated", update);
      commit("SET_INVITE", update);
    });
  },
  async findInvite({ commit }, inviteId) {
    var db = firebase.firestore();
    var inviteRef = await db
      .collection("invitations")
      .doc(inviteId)
      .get();
    let invited = inviteRef.data();
    if (!invited.id) invited.id = inviteRef.id;
    commit("SET_RECEIVED_INVITE", invited);
  }
};

export const getters = {
  userInvite(state) {
    return state.invite;
  },
  receivedInvite(state) {
    return state.receivedInvite;
  }
};
