var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "box is-align-content-center",
      attrs: { id: "herocontent" }
    },
    [
      _vm._m(0),
      _c("p", { staticClass: "has-text-centered mt-6 padme" }, [
        _vm._v(
          " Gobo is a ticketing solution built by artists and venue managers to keep ticketing easy and affordable. We charge a flat 5% fee that includes credit card fees, and give you tools to help run your shows and build your audience. "
        )
      ]),
      _c("div", { staticClass: "buttons is-centered mt-6" }, [
        _c(
          "a",
          { staticClass: "button is-primary", on: { click: _vm.signmeup } },
          [_vm._v(" Start Selling Tickets Now ")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "has-text-centered mt-6 padme" }, [
      _vm._v(" Ticket Fees Suck."),
      _c("br"),
      _vm._v(" Gobo Doesn't. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }