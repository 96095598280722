import firebase from "firebase/app";
import "firebase/firestore";

export const state = {
  lineItems: [],
  summary: {},
  discountError: "",
};

var cartRef = null;
var cartListener = null;

export const mutations = {
  ADD_LINE_ITEM(state, lineItem) {
    state.lineItems.push(lineItem);
  },
  ADD_DONATION(state, donation) {
    if (state.summary.donation) state.sumary.donation += donation;
    else state.summary.donation = donation;
    console.log("donation set", state.summary);
  },
  CLEAR_DONATION(state) {
    const newsum = state.summary;
    newsum.donation = 0;
    state.summary = newsum;
  },
  UPDATE_LINE_ITEM(state, lineItem) {
    let index = matchLineItem(lineItem);
    if (index > -1) state.lineItems[index] = lineItem;
  },
  SET_CART_SUMMARY(state, summary) {
    state.summary = summary;
  },
  SET_CART_TOTAL(state, total) {
    state.summary.total = total;
  },
  ORDER_COMPLETE(state) {
    state.summary.status == "complete";
  },
  INVALID_DISCOUNT_CODE(state) {
    state.discountError = "Invalid discount code.";
  },
  APPLY_PASS(state, pass) {
    if (!state.summary.passes) state.summary.passes = {};
    if (!state.summary.passes[pass.passId]) {
      state.summary.passes[pass.passId] = pass;
      state.summary.passes[pass.passId].uses = pass.uses;
    } else {
      state.summary.passes[pass.passId].discount += pass.discount;
      state.summary.passes[pass.passId].uses += pass.uses;
    }
    if (state.summary.passDiscountTotal) state.summary.passDiscountTotal = state.summary.passDiscountTotal + state.summary.passes[pass.passId].discount;
    else state.summary.passDiscountTotal = state.summary.passes[pass.passId].discount;
  },
  SET_DISCOUNT(state, discount) {
    console.log("Discounting", discount);
    if (discount.discountType == "comp") {
      state.lineItems.forEach((item) => {
        item.price = 0;
        item.total = 0;
        item.discountCode = discount.discountCode;
      });
    } else if (discount.discountType == "percent") {
      state.lineItems.forEach((item) => {
        item.originalPrice = item.price;
        item.price = item.price * discount.modifier;
        item.discount = item.originalPrice - item.price;
        item.total = item.total * discount.modifier;
        item.discountCode = discount.discountCode;
      });
    } else if (discount.discountType == "ammount") {
      state.lineItems.forEach((item) => {
        item.originalPrice = item.price;
        item.price = item.price - discount.modifier;
        item.discount = item.originalPrice - item.price;
        item.total = item.total - discount.modifier * item.quantity;
        item.discountCode = discount.discountCode;
      });
    }
  },
};

export const actions = {
  async addToCart({ commit }, lineItem) {
    var db = firebase.firestore();
    await cartCheck(commit);
    console.log("here we go");
    var matched = matchLineItem(lineItem);
    if (matched > -1) {
      lineItem.quantity =
        parseInt(lineItem.quantity) +
        parseInt(state.lineItems[matched].quantity);
      lineItem.total = lineItem.price * lineItem.quantity;
      commit("UPDATE_LINE_ITEM", lineItem);
    } else {
      await db
        .collection("carts")
        .doc(localStorage.cart)
        .collection("lineItems")
        .add(lineItem)
        .catch(function (error) {
          console.error("Error adding line item: ", error);
        });
      commit("ADD_LINE_ITEM", lineItem);
    }
    updateCartTotal(commit);
  },
  addDonation({ commit }, donation) {
    console.log("adding donation", donation);
    commit("ADD_DONATION", donation);
  },
  clearDonation({ commit }) {
    commit("CLEAR_DONATION");
  },
  applyPass({ commit }, pass) {
    commit("APPLY_PASS", pass);
  },
  async placeOrder({ commit }, header) {
    var db = firebase.firestore();
    var orderRef = await db
      .collection("orders")
      .add(header)
      .catch(function (error) {
        console.error("Error adding order header: ", error);
      });
    console.log(state.lineItems);
    for (let i = 0; i < state.lineItems.length; i++) {
      await db
        .collection("orders")
        .doc(orderRef.id)
        .collection("lineItems")
        .add(state.lineItems[i])
        .catch(function (error) {
          console.error("Error adding order line item: ", error);
        });
    }
    header.id = orderRef.id;
    cartRef = orderRef;
    cartListener = orderRef.onSnapshot(function (snapshot) {
      let update = snapshot.data();
      console.log("order updated", update);
      commit("SET_CART_SUMMARY", update);
    });
    orderRef.update({ status: "submitted" });
    commit("SET_CART_SUMMARY", header);
  },
  async paymentComplete({ commit }, result) {
    console.log("payment complete");
    cartRef.update({ status: "paymentComplete", paymentResult: result });
    //cartListener = null;
    //cartRef = null;
    commit("ORDER_COMPLETE");
  },
  async stopListener() {
    cartListener();
    cartListener = null;
    cartRef = null;
  },
  async getDiscount({ commit }, discountRequest) {
    var db = firebase.firestore();
    discountRequest.code = discountRequest.code.toUpperCase();
    var discounts = await db
      .collection("events")
      .doc(discountRequest.eventId)
      .collection("discounts")
      .where("discountCode", "==", discountRequest.code)
      .get();
    if (!discounts.empty) {
      commit("SET_DISCOUNT", discounts.docs[0].data());
      var newSummary = state.summary;
      newSummary.discountCode = discountRequest.code;
      var oldtotal = newSummary.total;
      updateCartTotal(commit);
      newSummary.discount = oldtotal - state.summary.total;
      commit("SET_CART_SUMMARY", newSummary);
    } else {
      commit("INVALID_DISCOUNT_CODE");
    }
  },
};

export const getters = {
  summary(state) {
    return state.summary;
  },
  lineItems(state) {
    return state.lineItems;
  },
  discountError(state) {
    return state.discountError;
  },
};

async function cartCheck(commit) {
  if (!state.summary) {
    var db = firebase.firestore();
    if (localStorage.cart) {
      var cartRef = db.collection("events").doc(localStorage.cart);
      var cart = await cartRef.get();

      if (cart.exists) {
        console.log("Found the freaking cart.");
        commit("SET_CART_SUMMARY", cart.data());
      } else {
        console.log("Could not find event " + localStorage.cart);
      }
      console.log("found the cart!", localStorage.cart);
    } else {
      var docRef = await db
        .collection("carts")
        .add({ created: Date.now() })
        .catch(function (error) {
          console.error("Error adding eventr: ", error);
        });

      console.log("Document written with ID: " + JSON.stringify(docRef.id));
      localStorage.cart = docRef.id;
      var objid = { id: docRef.id };
      var eventdoc = await docRef.get();
      var createdEvent = { ...objid, ...eventdoc.data() };
      createdEvent.id = docRef.id;
      commit("SET_CART_SUMMARY", createdEvent);
    }
  }
}

function updateCartTotal(commit) {
  var total = 0;
  for (var i = 0; i < state.lineItems.length; i++) {
    if (state.lineItems[i].total) total += state.lineItems[i].total;
  }
  commit("SET_CART_TOTAL", total);
  console.log("Cart total: ", total);
}

function matchLineItem(lineItem) {
  var matched = -1;
  console.log("lip", state.lineItems.length);
  for (let i = 0; i < state.lineItems.length; i++) {
    console.log(state.lineItems[i], lineItem);
    if (
      state.lineItems[i].eventId == lineItem.eventId &&
      state.lineItems[i].dateId == lineItem.dateId &&
      state.lineItems[i].tierId == lineItem.tierId
    ) {
      matched = i;
    }
  }
  console.log("match this shit ", matched);
  return matched;
}
