<template>
  <div>
    <div
      v-if="userInvite.status != 'submitted' && userInvite.status != 'emailed'"
      id="inviter"
    >
      <h3 v-if="!userInvite.newProfile">
        Share your {{ userInvite.profileName }} profile with a co-owner.
      </h3>
      <h3 v-if="userInvite.newProfile && invitedRole == 'Venue'">
        Invite a venue to use Gobo.
      </h3>
      <h3 v-if="userInvite.newProfile && invitedRole == 'Artist'">
        Invite an artist to use Gobo.
      </h3>
      <div class="field">
        <label class="label">{{ invitedRole }} Name</label>
        <div class="control">
          <input
            type="text"
            class="input"
            v-model="userInvite.profileName"
            disabled="true"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">{{ invitedRole }} Email</label>
        <div class="control">
          <input type="text" class="input" v-model="userInvite.email" />
        </div>
      </div>
      <div class="field">
        <label class="label">Email Message</label>
        <div class="control">
          <textarea type="text" class="textarea" v-model="userInvite.message" />
        </div>
      </div>
      <div class="buttons is-centered">
        <button v-if="false" class="button" @click="cancel">Cancel</button>
        <button
          class="button is-primary"
          @click="confirm"
          :disabled="!emailValid"
          :class="{ 'is-loading': inProgress }"
        >
          Send Invite
        </button>
      </div>
    </div>
    <div
      id="invited"
      v-if="userInvite.status == 'submitted' || userInvite.status == 'emailed'"
    >
      <h3>An invite has been sent to {{ userInvite.email }}!</h3>
      <p>Thank you for sharing the Gobo experience!</p>
      <div class="figure">
        <img src="/img/illustration-email.png" alt="email sent" />
      </div>
      <div class="buttons is-centered">
        <button class="button">Invite Another Artist</button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  name: "InviteArtist",
  data() {
    return {
      // eslint-disable-next-line no-useless-escape
      emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      userInvite: "userInvite"
    }),
    emailValid() {
      return this.emailRegex.test(this.userInvite.email);
    },
    invitedRole() {
      if (this.userInvite.festivalId) return "Festival";
      return this.userInvite.venueId ? "Venue" : "Artist";
    },
    inProgress() {
      return (
        this.userInvite.status == "submitting" ||
        this.userInvite.status == "submitted"
      );
    }
  },
  methods: {
    cancel() {
      store.dispatch("confirm", false);
      this.$emit("canceled");
    },
    confirm() {
      console.log("sending invite");
      this.userInvite.status = "submitting";
      store.dispatch("submitInvite", this.userInvite);
      this.$emit("canceled");
    }
  }
};
</script>

<style>
.figure {
  display: flex;
  padding: 80px;
  justify-content: center;
}
</style>
