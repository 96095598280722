import firebase from "firebase/app";
import "firebase/firestore";
import Bugsnag from "@bugsnag/js";

export const state = {
  discountsEventId: null,
  discounts: []
};

//let currentEventId = null;

export const mutations = {
  ADD_DISCOUNT(state, discount) {
    state.discounts.push(discount);
  },
  SET_DISCOUNTS(state, discounts) {
    state.discounts = discounts;
  },
  SET_DISCOUNTS_EVENT_ID(state, eventId) {
    console.log("stashing event id", eventId);
    state.discountsEventId = eventId;
  },
  UPDATE_DISCOUNT(state, discount) {
    var index = state.discounts.findIndex(disc => disc.id == discount.id);
    if (index == -1)
      state.discounts[
        state.discounts.findIndex(disc => disc.id == -1)
      ] = discount;
    else state.discounts[index] = discount;
    state.discounts = [...state.discounts];
  },
  REMOVE_DISCOUNT(state, discount) {
    state.discounts = state.discounts.filter(disc => disc.id != discount.id);
  }
};

export const actions = {
  stashCurrentEventIdForDiscountsThisIsDumb({ commit }, eventId) {
    commit("SET_DISCOUNTS_EVENT_ID", eventId);
  },
  async newDiscount({ commit }) {
    const discount = {
      id: -1,
      state: "new",
      selectDates: false,
      selectedDates: []
    };
    commit("ADD_DISCOUNT", discount);
  },
  async fetchDiscounts({ commit }, eventId) {
    var db = firebase.firestore();
    console.log("Fetching discounts for event ", eventId);
    commit("SET_DISCOUNTS_EVENT_ID", eventId);
    //currentEventId = eventId;
    var docRef = db
      .collection("events")
      .doc(eventId)
      .collection("discounts");
    var discounts = await docRef.get();
    if (!discounts.empty) {
      console.log("Found the discounts.", discounts);
      commit(
        "SET_DISCOUNTS",
        discounts.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find any discounts for this event. Adding blank.");
    }
  },
  async saveDiscount({ commit, state }, discount) {
    console.log("Saving discount");
    console.log("get the root down", state.discountsEventId);
    // Ensure upper case
    discount.discountCode = discount.discountCode.toUpperCase();
    // update modifier
    if (discount.discountType == "percent" && discount.percentOff)
      discount.modifier = discount.percentOff / 100;
    if (discount.discountType == "dollarsOff" && discount.dollarsOff)
      discount.modifer = discount.dollarsOff;
    var db = firebase.firestore();
    if (discount.id != -1) {
      console.log("Existing discount. Updating.");
      await db
        .collection("events")
        .doc(state.discountsEventId)
        .collection("discounts")
        .doc(discount.id)
        .update(discount)
        .catch(function(error) {
          console.error("Error updating discount id: ", error);
          Bugsnag.notify(error);
        });
    } else {
      console.log("Saving new discount.", discount, state.discountsEventId);
      var docRef = await db
        .collection("events")
        .doc(state.discountsEventId)
        .collection("discounts")
        .add(discount)
        .catch(function(error) {
          console.error("Error adding discount: ", error);
          Bugsnag.notify(error);
        });
      console.log("Discount written with ID: " + JSON.stringify(docRef.id));
      discount.id = docRef.id;
      await db
        .collection("events")
        .doc(state.discountsEventId)
        .collection("discounts")
        .doc(docRef.id)
        .update({ id: docRef.id })
        .catch(function(error) {
          console.error("Error updating discount id: ", error);
          Bugsnag.notify(error);
        });
    }
    commit("UPDATE_DISCOUNT", discount);
  },
  async deleteDiscount({ commit }, event) {
    console.log("Deleting discount", event.discount.id);
    if (event.discount.id == -1) commit("REMOVE_DISCOUNT", event.discount);
    else {
      var db = firebase.firestore();
      await db
        .collection("events")
        .doc(state.discountsEventId)
        .collection("discounts")
        .doc(event.discount.id)
        .delete()
        .catch(function(error) {
          Bugsnag.notify(error);
          console.error("Error deleting event discount code: ", error);
        });
      commit("REMOVE_DISCOUNT", event.discount);
    }
  }
};

export const getters = {
  discounts(state) {
    return state.discounts;
  }
};
