var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c("div", { staticClass: "avatar-generic" }, [
        _vm.user.avatar
          ? _c("img", { attrs: { src: _vm.user.avatar, alt: "" } })
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }