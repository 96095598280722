import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/storage";
require("@/assets/gobo-app.scss");
// Import Duet Date Picker
import { defineCustomElements } from "@duetds/date-picker/dist/loader";
import * as VueGoogleMaps from "gmap-vue";
import VueCroppie from "vue-croppie";
import "croppie/croppie.css";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import Hotjar from "vue-hotjar";
import InputFacade from 'vue-input-facade'

Vue.use(VueCroppie);
Vue.use(VueQuillEditor, { toolbar: ["bold", "italic"] });
Vue.use(InputFacade);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBxt7GTz4w74LxqVtx31Ert13X1EmG3hhk",
    libraries: "places" // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'gmap-vue/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
});

// ...
// configure Vue.js to ignore Duet Date Picker
Vue.config.ignoredElements = [/duet-\w*/];

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBxt7GTz4w74LxqVtx31Ert13X1EmG3hhk",
    libraries: "places",
    map_id: "a7f1aa6c21b72aae"
  }
});

Vue.use(Hotjar, {
  id: "2418098",
  isProduction: true,
  snippetVersion: 6
});

// Register Duet Date Picker
defineCustomElements(window);

Vue.config.productionTip = false;

var app = "";

const configOptions = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASURMENTID
};

firebase.initializeApp(configOptions);
firebase.analytics();
firebase.storage();

Vue.prototype.$analytics = firebase.analytics();

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
  if (user && user.uid) Bugsnag.setUser(user.uid, user.email, user.displayName);

  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
});

Bugsnag.start({
  apiKey: "c2a4564b5e77590df1f82d66f2e0b61f",
  plugins: [new BugsnagPluginVue()],
  releaseStage: process.env.NODE_ENV,
  appVersion: "1.1"
});
const bugsnagVue = Bugsnag.getPlugin("vue");
bugsnagVue.installVueErrorHandler(Vue);
