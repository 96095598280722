<template>
  <div>
    <img id="logo" src="/img/gobo-black-square.png" alt="Gobo Logo" />
    <h3>{{ header }}</h3>
    <p>{{ message }}</p>
    <div class="buttons is-centered">
      <button class="button is-fullwidth" @click="cancel">Cancel</button>
      <button class="button is-fullwidth is-primary" @click="confirm">
        {{ cta }}
      </button>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Confirm",
  data() {
    return {};
  },
  props: {
    header: String,
    message: String,
    cta: String
  },
  methods: {
    cancel() {
      store.dispatch("confirm", false);
      this.$emit("canceled");
    },
    confirm() {
      console.log("comfirmed");
      store.dispatch("confirm", true);
      this.$emit("canceled");
    }
  }
};
</script>
