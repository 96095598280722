var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "wrapper", staticClass: "date-slider" }, [
    _vm.showLeftSlider
      ? _c(
          "span",
          {
            staticClass: "is-hidden-touch arrow",
            staticStyle: { "padding-right": "5px" },
            attrs: { id: "leftsliderbutton" },
            on: { click: _vm.slideRight }
          },
          [
            _c("img", {
              attrs: {
                src: "/img/icons/icon-arrow-left.png",
                alt: "slide left"
              }
            })
          ]
        )
      : _vm._e(),
    _vm.dates.length > 1 && _vm.width > 0
      ? _c(
          "div",
          {
            ref: "viewport",
            staticClass: "date-slider-viewport",
            style: { width: _vm.viewportwidth + "px" }
          },
          [
            !_vm.initializing
              ? _c(
                  "div",
                  {
                    ref: "slider",
                    staticClass: "date-slider-slide",
                    style: {
                      transform: `translate(${_vm.currentOffset}px, 0)`,
                      transition: "transform 0.5s ease 0s"
                    }
                  },
                  _vm._l(_vm.filtereddates, function(date) {
                    return _c(
                      "span",
                      {
                        key: date.id,
                        ref: "sliderItem",
                        refInFor: true,
                        staticClass: "date-slider-date",
                        class: { selected: date == _vm.selectedDate },
                        on: {
                          click: function($event) {
                            return _vm.setDate(date)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.shortDate(date)) + " ")]
                    )
                  }),
                  0
                )
              : _vm._e()
          ]
        )
      : _vm._e(),
    _vm.showRightSlider
      ? _c(
          "span",
          {
            staticClass: "is-hidden-touch arrow",
            staticStyle: { "padding-left": "5px" },
            attrs: { id: "rightsliderbutton" },
            on: { click: _vm.slideLeft }
          },
          [
            _c("img", {
              attrs: {
                src: "/img/icons/icon-arrow-right.png",
                alt: "slide right"
              }
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }